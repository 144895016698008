import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })


export class SoftServicesService {
    constructor(public httpClient: HttpClient) { }
    body: any;


    //to get SS checklist
    getSoftServiceRetailData(data) {

        return this.httpClient.post(environment.authApiBase + "/softServices/getSoftServiceRetailData", data);
    }

    //to get SS dashboard/details for reject-approved //TODO common for all status
    getSoftServiceStatus(data) {

        return this.httpClient.post(environment.authApiBase + "/softServices/getSoftServiceStatus", data);
    }

    getSpecificBuildingListing(data) {
        return this.httpClient.post(environment.authApiBase + "/softServices/getSpecificBuildingListing", data);
    }

    getPropertyInstanceDetails(data) {
        return this.httpClient.post(environment.authApiBase + "/softServices/getPropertyInstanceDetails", data);
    }



    //to get listing of ss retail checklist data for specific instance
    getInstaneRetChecklistData(data) {
        return this.httpClient.post(environment.authApiBase + "/softServices/getInstaneRetChecklists", data);
    }

    //to get listing of ss retail checklist  instance details
    getInstaneReatilDetails(data) {
        return this.httpClient.post(environment.authApiBase + "/softServices/getInstaneReatilDetails", { id: data });
    }

    //to update retail checklist details instance comments
    updateRetailInstance(data) {
        return this.httpClient.post(environment.authApiBase + "/softServices/updateRetailInstance", data);
    }

}