import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })


export class MasterTableService {
  body: any;
  constructor(public httpClient: HttpClient) { }

  //to get roles from master table
  getRoles() {
    return this.httpClient.post(environment.authApiBase + '/master/getRoles', { withCredentials: false });
  }

  //to get stautus 1,2 from master table
  getStatus(flagObject) {
    return this.httpClient.post(environment.authApiBase + '/master/getStatus', flagObject, { withCredentials: false });
  }

  getMasterRecordsList(tableName: string) {
    return this.httpClient.post(environment.authApiBase + '/master/allMasterRecordsList', { tableName }, { withCredentials: false });
  }

  updateMasterRecords(data: any) {
    return this.httpClient.post(environment.authApiBase + '/master/updateMaster', data, { withCredentials: false });
  }

  addNewMasterRecords(data: any) {
    return this.httpClient.post(environment.authApiBase + '/master/addMaster', data, { withCredentials: false });
  }

  getMasterData(master: string) {
    let data = {
      masterName: master
    }
    return this.httpClient.post(environment.authApiBase + '/master/getSpStatusMasterData', data, { withCredentials: false });
  }

}