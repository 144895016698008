import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, PRIMARY_OUTLET } from '@angular/router';
import { filter } from 'rxjs/operators';
import { map } from 'rxjs/internal/operators';
import { Location } from "@angular/common";
import { RouteEventsService } from 'src/app/utilService';


@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  providers: [
    RouteEventsService
  ]
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
  public breadcrumbs;
  public title: string
  breadcrumbObject: any = ''
  URL: any = ''
  constructor(private routeEventsService: RouteEventsService, private location: Location, private activatedRoute: ActivatedRoute,
    private router: Router, private route: ActivatedRoute) {

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(map(() => this.activatedRoute))
      .pipe(map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }))
      .pipe(filter(route => route.outlet === PRIMARY_OUTLET))
      .subscribe(route => {
        let snapshot = this.router.routerState.snapshot;
        let title = route.snapshot.data['title'];
        let breadcrumb1 = route.snapshot.data['breadcrumb1'];    //new
        let breadcrumb2 = route.snapshot.data['breadcrumb2'];    //new
        let breadcrumb3 = route.snapshot.data['breadcrumb3'];    //new

        this.breadcrumbObject = {
          breadcrumb1, breadcrumb2, breadcrumb3
        }

        this.URL = snapshot.url //to set for global purpose

        if (breadcrumb1 != 'Sites') {
          if (this.URL.includes("on-boarding/sitesListing?data=") == true) {
            atob(localStorage.getItem('fromClients')) == 'true' ? breadcrumb1 = '' : breadcrumb1;
          }
          else if (this.URL.includes("on-boarding/editClient?data") == true) {
            atob(localStorage.getItem('fromClients')) == 'true' ? breadcrumb1 = '' : breadcrumb1;
          }
          else if ((this.URL.includes("asset-management/updateJobHistoryList?data=") ||
            this.URL.includes("asset-management/asset-contacts-list?data=") ||
            this.URL.includes("asset-management/updateAmc?data="))
            &&
            atob(localStorage.getItem('fromAssets')) == 'true'
          ) {
            atob(localStorage.getItem('fromAssets')) == 'true' ? breadcrumb1 = 'Edit' : breadcrumb1;
          }
        }


        let parent = route.parent.snapshot.data['breadcrumb'];
        let child = route.snapshot.data['breadcrumb'];

        this.breadcrumbs = {};
        this.title = title;
        this.breadcrumbs = {
          "parentBreadcrumb": parent,
          "childBreadcrumb": child,
          "breadcrumb1": breadcrumb1,
          "breadcrumb2": breadcrumb2,
          "breadcrumb3": breadcrumb3
        }
      });


  }

  ngOnInit() { }
  //TODO : comments will be added for functionality
  getBackToPrevious(lhsMenu, flag) {
    //for parent breadcrumb it will be no click event
    if (this.breadcrumbObject.breadcrumb1 == undefined && this.breadcrumbObject.breadcrumb2 == undefined
      && this.breadcrumbObject.breadcrumb3 == undefined) {
      return;
    }


    // console.log(this.URL)
    // console.log(this.routeEventsService.previousRoutePath.value)
    // console.log(lhsMenu, flag)
    if (flag == 1) {

      // if (lhsMenu == "Assets" && this.routeEventsService.previousRoutePath.value.includes("/on-boarding/asset-management/site-assets?suuid=")) {
      //   this.router.navigate(["/asset-management/asset"]);
      //   return;
      // }

      switch (lhsMenu) {
        case "Service-Provider":
          this.location.back();
          break;
        case "Assets":
          this.location.back();
          break;
        case "Clients":
          this.router.navigate(["/on-boarding/all-clients"]);
          break;
        case "Reminders":
          this.router.navigate(["/creditManagment/clientOnBoardingListing"]);
          break;
        case "Sites":
        case this.URL.includes("/on-boarding/asset-management/site-assets?suuid"):
          this.location.back();
          break;
        case "Sites":
          this.router.navigate(["/on-boarding/sitesListing"]);
          break;
        case "Properties":
          this.router.navigate(["/soft-services/retailsProperties"]);
          break;
        case "Buildings":
          this.router.navigate(["/soft-services/retailsBuilding"]);
          break;
        case "Room Cleanup":
          this.location.back();
          break;
        case "Training":
          this.router.navigate(["/training-material/training-dashboard"]);
          break;
        case "Checklist":
          this.router.navigate(["/TDM/standardChecklist"]);
          break;  
        case "Logsheet":
        this.router.navigate(["/TDM/standardLogsheet"]);
        break;   
        case "Attendance":
          case this.URL.includes("/attendance/attendance-dashboard?suuid"):
            this.location.back();
            break;
        case "Attendance":
          this.router.navigate(["/attendance/attendance-dashboard"]);
          break;
        case "Work Orders":
          this.router.navigate(["/work-order/listing"]);
            break;
        case "All MMRs":
          this.router.navigate(["/TDM/MMRListing"]);
          break;
      }

      if (lhsMenu == "Users") {
        this.location.back();
      }

    }

    if (flag == 2) {
      if (lhsMenu == "Housekeeping Status" || lhsMenu == "Users" || lhsMenu == "Tickets" || lhsMenu == "Assets" || lhsMenu == "Sites" || lhsMenu == "Checklists" || lhsMenu == "Logsheets" || lhsMenu == "View" || lhsMenu == "Edit" || lhsMenu == "Properties" || lhsMenu == "Buildings" || lhsMenu == "Regions" || lhsMenu == "Clusters") {

        this.location.back();
      }
    }


    /*//for parentBreadcrumb
    if (flag == 3) {
      if (this.URL.includes("/asset-management/asset?suuid")) {
        this.router.navigate(["/on-boarding/all-sites"]);

        return;
      }
    }*/
  }

  ngOnDestroy() {
    this.breadcrumbs = {};
  }

}
