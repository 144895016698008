import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { Router, NavigationExtras, ActivatedRoute } from "@angular/router";
import { AssetManagementService } from "../../shared/services/index";



@Component({
  selector: "app-asset",
  templateUrl: "./asset.component.html",
  styleUrls: ["./asset.component.scss"]
})
export class AssetComponent implements OnInit, OnDestroy {
  public assets = [];
  responseData;
  loggedUserSites = JSON.parse(atob(localStorage.getItem('spUserData')))['uSites'];
  arrSiteList = [];
  showMoreFlag = true;
  showHideGenerateQRButton : boolean = true;
  qrCodeSourceData: any;
  public columns = [
    { prop: "SiteName", name: "SiteName" },
    { prop: "AssetUUID", name: "AssetUUID" },
    { prop: "AssetCode", name: "AssetCode" },
    { prop: "AssetStatus", name: "AssetStatus" },
    { prop: "AssetCategory", name: "AssetCategory" },
    { prop: "AssetType", name: "AssetType" },
    { prop: "AssetName", name: "AssetName" },
    { prop: "AssetBuilding", name: "AssetBuilding" },
    { prop: "AssetLocation", name: "AssetLocation" },
    { prop: "AssetMake", name: "AssetMake" },
    { prop: "AssetModelNumber", name: "AssetModelNumber" },
    { prop: "AssetSerialNumber", name: "AssetSerialNumber" },
    { prop: "AssetSpecification", name: "AssetSpecification" },
    { prop: "AssetCommissionDate", name: "AssetCommissionDate" },
    { prop: "AssetHandoverDate", name: "AssetHandoverDate" },
    { prop: "AssetWarrantyDateFrom", name: "AssetWarrantyDateFrom" },
    { prop: "AssetWarrantyDateTo", name: "AssetWarrantyDateTo" },
    { prop: "AssetRelatedComments", name: "AssetRelatedComments" }
  ];

  @ViewChild("assetTble", { static: true }) table: any;
  rows: any;
  rowsObjectAsset: any;
  scrollbarH: boolean = true;
  siteId: any = '';
  routerLink: string;
  dynamicButton: string;
  constructor(private route: ActivatedRoute, private router: Router, private AssetManagementService: AssetManagementService) {


    this.route.queryParams.subscribe(params => {
      if (params["suuid"]) {
        this.siteId = atob(params["suuid"]);
      }
    });

  }

  ngOnInit() {
    //to get asset listing data
    this.getAssetsList();
    //handle UI as per conditions
    this.asPerDependencies()
  }

  //handle UI as per conditions
  asPerDependencies() {

    if (this.siteId == '') {
      this.routerLink = "/on-boarding/all-sites";
      this.dynamicButton = "Sites";
    } else {
      this.routerLink = "/asset-management/asset-add";
      this.dynamicButton = "Add Asset";
    }


  }


  //when navigates to other view/page,arrays are cleared
  ngOnDestroy() {
    //making arrays empty to avoid memory
    this.rowsObjectAsset = []
    this.rows = []
    this.columns = []
    this.responseData = []

  }
  //to get listing of assets
  getAssetsList() {

    if (this.loggedUserSites !== null) {
      for (let i = 0; i < this.loggedUserSites.length; i++) {
        this.arrSiteList.push(this.loggedUserSites[i].sID);
      }
    }
    if(this.siteId){
      this.showHideGenerateQRButton = false;
    }
    const reqData = {
      SiteUUID: this.siteId,
      ClientUUID: this.siteId.substring(0, 7),
      SpUUID: JSON.parse(atob(localStorage.getItem('spUserData')))['SpUUID'],
      roleId: JSON.parse(atob(localStorage.getItem('spUserData')))['uRoleId'],
      siteIdList: this.arrSiteList
    }
    //to call service (API) to get listing of data
    this.AssetManagementService.getListAsset(reqData).subscribe(data => {
      this.rows = data['result'];
      localStorage.setItem('listingSpinner', "false");
      this.rowsObjectAsset = [...this.rows]
      this.responseData = [...this.rows]
    });

  }


  //searching for listing
  updateFilter(event) {
    const val = event.toLowerCase().trim();
    //if searchbox is empty then all data

    if (!val) {
      // this.getAssetsList();
      this.rows = this.responseData;
      return
    }
    // this.rows = JSON.parse(this.rowsObjectAsset);
    const rows = this.responseData.filter(index => {

      index.AssetUUID = index.AssetUUID.toString();
      return (

        index.AssetUUID.toLowerCase().indexOf(val) !== -1 ||
        index.AssetCode.toLowerCase().indexOf(val) !== -1 ||
        index.statusName.toLowerCase().indexOf(val) !== -1 ||
        index.AssetName.toLowerCase().indexOf(val) !== -1 ||
        index.typeName.toLowerCase().indexOf(val) !== -1 ||
        index.AssetLocation.toLowerCase().indexOf(val) !== -1 ||
        index.AssetMake.toLowerCase().indexOf(val) !== -1 ||
        index.AssetSerialNumber.toLowerCase().indexOf(val) !== -1 ||
        index.AssetModelNumber.toLowerCase().indexOf(val) !== -1 ||
        index.AssetBuilding.toLowerCase().indexOf(val) !== -1 ||
        !val
      );

    });
    this.rows = rows;

  }


  //to show more columns
  show_more() {
    this.showMoreFlag = false;
    this.scrollbarH = true
    //window.scrollTo(10000, 0); // it moves horizontally to scrollbar
  }

  //to show less columns
  show_less() {
    this.showMoreFlag = true;
  }



  //when clicked on specific row and navigated to asset view page
  onActivate(row, event) {

    if (event.type == "click") {
      let navigationExtras: NavigationExtras = {
        queryParams: {
          data: btoa(row["AssetUUID"])
        }
      };
      this.router.navigate(["/asset-management/asset-view"], navigationExtras);

    }

  }

  //to show TDM dashboard
  onDashboard(row, flag) {

    //to set in url segments and string using from binary to ASCII
    let navigationExtras: NavigationExtras = {
      queryParams: {
        data: btoa(row["AssetUUID"])
      }
    };

    //flag 1 is for Checklist dashboard and 2 is for logsheet dashboard
    if (flag == 1) {
      this.router.navigate(["/TDM/dashboard"], navigationExtras);
    } else if (flag == 2) {
      this.router.navigate(["/TDM/logsheetDashboard"], navigationExtras);
    }
    else if (flag == 3) {
      if (this.dynamicButton == "Sites") {
        this.router.navigate(["/on-boarding/all-sites"])
      } else if (this.dynamicButton == "Add Asset") {
        var navigationExt: NavigationExtras = {
          queryParams: {
            suuid: btoa(this.siteId)
          }
        };
        this.router.navigate(["/asset-management/asset-add"], navigationExt)
      }


    }
    else if (flag == 4) {
      this.router.navigate(["/TDM/PPMDashboard"], navigationExtras);
    } else if (flag == 5) {
      this.router.navigate(["/TDM/LogsheetReport"], navigationExtras);
    }

    /* else {
       let navigationExtras: NavigationExtras = {
         queryParams: {
           suuid: btoa(this.siteId)
         }
       };

       if (this.siteId == '') {
         this.router.navigate([row]);
       } else {
         this.router.navigate([row], navigationExtras);
       }
     }*/
  }

   //to generate all asset QR code
   generateQRCode(viewFlag) {
       //if array response is empty then no generation
    if (  this.rowsObjectAsset.length < 1) {
      return false;
    }

    let generatingQRArray = [{ unitIds: [],Id: [],Name:[], SpUUID: this.siteId.slice(0,3),ParentName:[], SiteUUID:this.siteId, SiteName: this.rowsObjectAsset[0]['SiteName']  }];

    this.rowsObjectAsset.forEach(function (item) {
      if (item.AssetQRCodeURL == '' || item.AssetQRCodeURL == '""') {
        generatingQRArray[0].Id.push(item.AssetUUID)
        // generatingQRArray[0].SpUUID.push(item.AssetUUID.slice(0, 3))
        generatingQRArray[0].ParentName.push("")
        generatingQRArray[0].Name.push(item.AssetName)
      }
      generatingQRArray[0].unitIds.push(item.AssetUUID)

    });
   
    this.qrCodeSourceData = generatingQRArray;
    let navigationExtras: NavigationExtras = {
      queryParams: {
        data: btoa(JSON.stringify(this.qrCodeSourceData)),
        hash: btoa(viewFlag)
      }
    };
    this.router.navigate(['/QRCodes'], navigationExtras);


   }
}
