import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })


export class OnboardingService {

  constructor(public httpClient: HttpClient) { }

  getClientList(param) {

    return this.httpClient.post(environment.authApiBase + '/client/getClientList', param, { withCredentials: false });
  }

  getClientListForUser(spUuid) {

    return this.httpClient.post(environment.authApiBase + '/client/getClientListForUser', { spUuid: spUuid }, { withCredentials: false });
  }
  getClientInstanceDetails(clientInstanceId: string) {

    return this.httpClient.post(environment.authApiBase + '/client/getClientInstanceDetails', { clientInstanceId: clientInstanceId }, { withCredentials: false });
  }

  //property listing for required red data
  getClientPropertyListing(reqData) {
    return this.httpClient.post(environment.authApiBase + '/client/getRequiredPropertyListing', reqData, { withCredentials: false });
  }

  //site listing for specific client
  getClientSiteListing(clientInstanceId: any, loggedUserSites: any) {

    return this.httpClient.post(environment.authApiBase + '/client/getClientSiteListing', { clientInstanceId: clientInstanceId, uSites: loggedUserSites }, { withCredentials: false });
  }

  getSitesForSelectedClient(SpUUID: any, clientId: any) {

    return this.httpClient.post(environment.authApiBase + '/client/getSitesForSelectedClient', { SpUUID: SpUUID, cID: clientId, UserUUID: JSON.parse(atob(localStorage.getItem('spUserData')))['UserUUID'] }, { withCredentials: false });
  }

  getAccessedClients(UserUUID: any) {

    return this.httpClient.post(environment.authApiBase + '/client/getAccessedClients', { UserUUID: UserUUID }, { withCredentials: false });
  }



  getClientUserListing(clientInstanceId: string) {

    return this.httpClient.post(environment.authApiBase + '/client/getClientUserListing', { clientInstanceId: clientInstanceId, spUUID: JSON.parse(atob(localStorage.getItem('spUserData')))['SpUUID'] }, { withCredentials: false });
  }

  //to get listing of users as per role (all or for specific client selected)
  getUserList(spUuid: string, roleBasedApiCall, clientUuid) {

    var userAccess
    //TODO on role based logged user
    switch (roleBasedApiCall) {
      case 'allUsers':
        userAccess = 'all';//JSON.parse(atob(localStorage.getItem('spUserData')))['SpUUID']
        break;
      case 'specificClient':
        userAccess = 'specificClient'//JSON.parse(atob(localStorage.getItem('spUserData')))['SpUUID']
        break;
      case 'specificUserId':
        userAccess = 'specificUserId'//JSON.parse(atob(localStorage.getItem('spUserData')))['SpUUID']
        break;
    }


    return this.httpClient.post(environment.authApiBase + '/getUserList', { spUuid: spUuid, userAccess: userAccess, clientUuid: clientUuid }, { withCredentials: false });


  }

  //to get details of specifci user ID
  getUserDetails(SpUUID, UserUUID) {
    return this.httpClient.post(environment.authApiBase + '/user/getUserDetails', { UserUUID: UserUUID, SpUUID: SpUUID }, { withCredentials: false });
  }


  getSitesList(clientUuid: string) {
    return this.httpClient.post(environment.authApiBase + '/site/getSiteList', { clientUuid: clientUuid }, { withCredentials: false });
  }

  //to get listing of all sites for specific SPid
  getAllSitesListing(param) {
    return this.httpClient.post(environment.authApiBase + '/site/getAllSitesListing', param, { withCredentials: false });
  }

  //to get site for specific site
  getSiteInstanceDetails(spUuid) {
    return this.httpClient.post(environment.authApiBase + '/site/getSiteInstanceDetails', { spUuid: spUuid }, { withCredentials: false });
  }

  //to get site for specific site
  addSiteInstanceDetails(siteDetails) {
    return this.httpClient.post(environment.authApiBase + '/site/addSiteDetails', siteDetails, { withCredentials: false });
  }

  deleteSelectedSite(siteId) {
    return this.httpClient.post(environment.authApiBase + '/site/deleteSiteInstanceDetails', { siteId: siteId }, { withCredentials: false });
  }

  //submit client details, used for both add/update this method
  saveClientDetails(clientData, crudFlag) {

    switch (crudFlag) {
      case "add":
        return this.httpClient.post(environment.authApiBase + '/client/addClientDetails', { clientData }, { withCredentials: false });
      case "update":
        return this.httpClient.post(environment.authApiBase + '/client/updateClientDetails', { clientData }, { withCredentials: false });
    }

  }

  //submit site details, used for both add/update this method
  saveSiteDetails(siteData, crudFlag) {

    switch (crudFlag) {
      case "add":
        return this.httpClient.post(environment.authApiBase + '/site/addSiteDetails', { siteData }, { withCredentials: false });
      case "update":
        return this.httpClient.post(environment.authApiBase + '/site/updateSiteDetails', { siteData }, { withCredentials: false });

    }

  }

  //to get user supervisor from db related to spUUID
  getUserSupervisor(spUuid) {
    return this.httpClient.post(environment.authApiBase + '/user/getUserSupervisor', { spUuid: spUuid }, { withCredentials: false });
  }


  //submit user details, used for both add/update this method
  saveUserDetails(userData, crudFlag) {

    if (crudFlag == 'add') {
      return this.httpClient.post(environment.authApiBase + '/user/saveUserDetails', { userData, crudFlag: crudFlag }, { withCredentials: false });

    } else {
      return this.httpClient.post(environment.authApiBase + '/user/updateUserDetails', { userData, crudFlag: crudFlag }, { withCredentials: false });

    }

  }

  getSitesByClient(params){
    return this.httpClient.post(environment.authApiBase + '/site/getSitesByClient', params, { withCredentials: false });
  }

  generateQRCode(params){
    return this.httpClient.post(environment.authApiBase + '/site/generateQRCode', params, { withCredentials: false });
  }

  displayQRCode(siteId){
    return this.httpClient.post(environment.authApiBase + '/site/displayQRCode', {siteId: siteId}, { withCredentials: false });
  }

}