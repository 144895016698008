import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from '../../../environments/environment';
import { map } from "rxjs/operators";
import { CommonFunctionService } from "./common-functions.service";
import { Router } from "@angular/router";
//for detecting internet connction
import { Observable, fromEvent, merge, of } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { ToastrService } from "ngx-toastr";

@Injectable({ providedIn: 'root' })

export class AuthenticationService {
  online$: Observable<boolean>;

  constructor(private router: Router, public httpClient: HttpClient, public commonService: CommonFunctionService,
    private toastrService: ToastrService,
  ) {
    //for detecting internet connction
    this.online$ = merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(mapTo(true)),
      fromEvent(window, 'offline').pipe(mapTo(false))
    )
    this.networkStatus()
  }
  //to detect internet connection
  public networkStatus() {
    this.online$.subscribe(value => {
      if (value == false) {
        this.toastrService.warning('Internet connection is required or slow.');
      }
    })
  }


  //login service - API
  authenticateUser(uname: string, password: string) {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.httpClient.post(environment.authApiBase + '/authenticateUser', { uname: uname, password: password }, { withCredentials: false }).pipe(map(response => {
      if (response['error'] == false && response['message'] == 'Success' && response['statusCode'] == 200) {
        localStorage.setItem('spUserData', btoa(JSON.stringify(response['result'])));
        localStorage.setItem('token', JSON.stringify(response['result']['token']));
        return response;
      }
      else {
        return response;
      }
      // return false;
    }));
  }


  login(uname: string, password: string) {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.httpClient.post(environment.authApiBase + '/login', { uname: uname, password: password }, { withCredentials: true }).pipe(map(response => {
      this.commonService.clearLocalStorage();
      if (response && response['is_authenticated']) {
        localStorage.setItem('currentUser', JSON.stringify(response['current_user']));
        return true;
      }
      return false;
    }));
  }

  //logout from the system
  logout() {
    this.router.navigate(['/']);
    localStorage.clear();
    sessionStorage.clear();
    // this.commonService.clearLocalStorage();
    // return this.httpClient.get(environment.authApiBase + '/logout', { withCredentials: true }); // remove user from local storage to log user out
  }

  //for forgot password, to get opt on verified contact no.
  getVerifiedResponse(data, phoneNumber) {
    var newKeyName = "contactNumber";
    data[newKeyName] = phoneNumber;

    if (data.otpVar && (data.newPassword == "" && data.confirmPassword == "")) {
      return this.httpClient.post(environment.authApiBase + '/preAuth/getOtpVerified', data);
    } else if (!data.otpVar && (data.newPassword == "" && data.confirmPassword == "")) {
      return this.httpClient.post(environment.authApiBase + '/preAuth/getNumberVerified', data);
    } else if (data.newPassword && data.confirmPassword) {
      return this.httpClient.post(environment.authApiBase + '/preAuth/resetPassword', data);
    }


  }


  getLocationName() {
    return this.httpClient.post(environment.authApiBase + '/userAttendance/submitUserAttendance', { withCredentials: true });
  }

}