import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })

export class SoftServicesService {

  constructor(public httpClient: HttpClient) { }

  getRetailList() {
    return this.httpClient.post(environment.authApiBase + "/softServices/retailList", '', { withCredentials: false });
  }

  updateRetailSettings(data: any) {
    return this.httpClient.post(environment.authApiBase + "/softServices/updateRetails", data, { withCredentials: false });
  }

  deleteRetail(UnitTypeID: string) {
    return this.httpClient.post(environment.authApiBase + "/softServices/delete", { UnitTypeID }, { withCredentials: false });
  }

  //to get details of specifci user ID
  getUnitList() {
    return this.httpClient.post(environment.authApiBase + '/softServices/unitList', { withCredentials: false });
  }

  //to get details of specifci user ID
  getRetailUnitSummeryList(SpUUID, PropertyUUID) {
    return this.httpClient.post(environment.authApiBase + '/softServices/retailUnitSummeryList', { SpUUID: SpUUID, PropertyUUID: PropertyUUID }, { withCredentials: false });
  }

  //update Unit Summary
  updateUnitSummery(data) {
    return this.httpClient.post(environment.authApiBase + '/softServices/updateUnitSummery', data, { withCredentials: false });
  }

  uploadUnitsBulkData(data) {
    return this.httpClient.post(environment.authApiBase + '/softServices/uploadUnitsBulkData', data, { withCredentials: false });
  }

  generateUnitUUID(data) {
    let promise = new Promise((resolve, reject) => {
      this.httpClient.post(environment.authApiBase + '/softServices/generateUnitUUID', data, { withCredentials: false })
        .toPromise()
        .then(
          res => { 
            resolve(res);
          },
          msg => { 
            reject(msg);
          }
        );
    });
    return promise;
  }

  saveUnitData(data) {
    return this.httpClient.post(environment.authApiBase + '/softServices/saveUnitData', data, { withCredentials: false });
  }

  getRetailsBuildingList(data: any) {
    return this.httpClient.post(environment.authApiBase + '/softServices/retailsBuildingList', data, { withCredentials: false });
  }

  getRetailsUnitsList(data) {
    return this.httpClient.post(environment.authApiBase + '/softServices/retailsUnitsList', data, { withCredentials: false });
  }

  //to generate QR code of units
  generateQRCode(ids) {
    return this.httpClient.post(environment.authApiBase + '/softServices/generateQRCode', ids, { withCredentials: false });

  }

  deleteRetailsBuilding(BuildingUUID: string) {
    return this.httpClient.post(environment.authApiBase + "/softServices/delete", { BuildingUUID }, { withCredentials: false });
  }

  deleteRetailsUnits(UnitUUID: string) {
    return this.httpClient.post(environment.authApiBase + "/softServices/delete", { UnitUUID }, { withCredentials: false });
  }

  getRetailsClusterList() {
    return this.httpClient.post(environment.authApiBase + "/softServices/retailClusterList", { spUuid: JSON.parse(atob(localStorage.getItem('spUserData')))['SpUUID'], withCredentials: false });
  }

  submitRetailsBuilding(data: any) {
    return this.httpClient.post(environment.authApiBase + "/softServices/submitRetailsBuilding", data, { withCredentials: false });
  }

  submitRetailsUnits(data: any) {
    return this.httpClient.post(environment.authApiBase + "/softServices/submitRetailsUnits", data, { withCredentials: false });
  }

  getRetailsPropertiesList(data: any) {
    return this.httpClient.post(environment.authApiBase + '/softServices/retailsPropertiesList', data, { withCredentials: false });
  }

  deleteRetailsProperties(PropertyUUID: string) {
    return this.httpClient.post(environment.authApiBase + "/softServices/delete", { PropertyUUID }, { withCredentials: false });
  }

  getPropertiesSupervisorList(SpUUID: string) {
    return this.httpClient.post(environment.authApiBase + "/softServices/supervisorList", { SpUUID }, { withCredentials: false });
  }

  submitRetailsProperty(data: any) {
    return this.httpClient.post(environment.authApiBase + "/softServices/submitRetailsProperties", data, { withCredentials: false });
  }

  //to get region list as per specific client ID
  getRetailsRegionsList(data) {
    return this.httpClient.post(environment.authApiBase + '/softServices/retailsRegionsList', data, { withCredentials: false });
  }

  //to get cluster list as per specific region ID
  getRetailsClusters(data) {
    return this.httpClient.post(environment.authApiBase + '/softServices/retailsClusters', data, { withCredentials: false });
  }

  getFloorAndUnitsCountByBuilding(data){
    return this.httpClient.get(`${environment.authApiBase}/softServicesSchedule/getFloorAndUnitsCountByBuilding?buildingId=${data}`);
  }

  saveSchedulerData(data){
    return this.httpClient.post(`${environment.authApiBase}/softServicesSchedule/saveSchedulerData`,data);
  }

}
