export class ConfigDB {
  static data = {
    settings: {
      layout_type: "ltr",
      sidebar: { type: "compact-page", body_type: "sidebar-hover" },
      sidebar_setting: "default-sidebar",
      sidebar_backround: "dark-sidebar"
    },
    color: {
      layout_version: "light",
      color: "color-1",
      primary_color: "#4466f2",
      secondary_color: "#1ea6ec",
      mix_layout: "default"
    },
    router_animation: "fadeIn"
  };
}

// export class ConfigDB {
//   static data = {
//     settings: {
//       layout_type: "ltr",
//       sidebar: { type: "compact-page", body_type: "sidebar-hover" },
//       sidebar_setting: "default-sidebar",
//       sidebar_backround: "dark-sidebar"
//     },
//     color: {
//       layout_version: null,
//       color: null,
//       primary_color: null,
//       secondary_color: null,
//       mix_layout: "default"
//     },
//     router_animation: "fadeIn"
//   };
// }
