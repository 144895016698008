import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CustomAttributesService } from '../../services/custom-attributes.service';
import { tabDetails } from "./../../../utilService/class-declarations";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import * as $ from "jquery";
import { ToastrService } from 'ngx-toastr';
import { environment } from './../../../../environments/environment';
import { fileURLToPath } from 'url';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-dynamic-attribute-set',
  templateUrl: './dynamic-attribute-set.component.html',
  styleUrls: ['./dynamic-attribute-set.component.scss']
})
export class DynamicAttributeSetComponent implements OnInit {
  tabDetails = new tabDetails();
  @Input() viewFlag;
  @Input() Id;
  attributeSetList:any =[];
  settings:any;
  attributeSetData:any =[];
  modalTitle:any ="";
  dynamicFormGroup:FormGroup;
  formTemplate:any;
  formTemplateList:any; 
  selectedAttributeSetId:any;
  resAttributeData:any =[];
  MultiSelectDropdownSettings: IDropdownSettings = {};
  attributeValues:any;
  objectKeys = Object.keys;
  uploadedFiles:any=[];
  selectedItemList:any=[];
  selectedItemIds:any=[];
  requiredTextboxValue:any=false;

  constructor(private customAttributesService:CustomAttributesService, private modalService: NgbModal, private toastrService: ToastrService) { }

  ngOnInit() {
    this.settings = {
      add: false ,
      columns: { SequenceId: { title: 'Sr.No' }, AttributeLabel: { title: 'AttributeLabel'}, 
      AttributeDataSet: { title: 'Attribute DataSet'}, IsActive: { title: 'Is Active' } },
      actions:false,
      hideSubHeader: true
    };
    this.tabDetails.icon = "fa fa-edit";
    this.tabDetails.label = "Edit";
    this.tabDetails.title = "Edit Attributes";
    this.getAttributeSet();
  }

  getAttributeSet(){
    let params={
      Id: this.Id,
      setFlag: 'Asset'
    }
    this.customAttributesService.getDynamicAttributeValues(params).subscribe(data=>{
      if(data["result"]){
        this.resAttributeData=JSON.parse(data["result"][0].CustomAttributes);
      }
    });
    this.customAttributesService.getAttributeSetBySiteId(params).subscribe(data=>{
      this.attributeSetData=[];
      if(data["result"]){
        this.attributeSetList = data["result"];
        var lookup = {};
        var items = this.attributeSetList;
        var attributeSets = [];
        for (var item, i = 0; item = items[i++];) {
          var setLabel = item.SetLabel;
        
          if (!(setLabel in lookup)) {
            lookup[setLabel] = 1;
            attributeSets.push(setLabel);          
          }
        }
        let controlJson:any;
        for(let i=0;i<attributeSets.length;i++){
          this.attributeSetData.push({SetName:attributeSets[i].toString(),Attributes:[],Controls:[],formValues:[],viewValues:[], fileValue:[]});
          let idx=0;
          for(let a=0;a<this.attributeSetList.length;a++){            
            if(attributeSets[i].toString()==this.attributeSetList[a].SetLabel){
              this.modalTitle=this.attributeSetList[a].SetName;
              
              let attr = this.attributeSetList[a];
                            
              switch (attr.AttributeType){
                case 'Datetime':
                  controlJson={
                    "type":"ngbDatePicker",
                    "label":attr.AttributeLabel,
                    "name": attr.AttributeName,
                    "isMandatory":attr.IsMandatory
                  }                
                  break;
                case 'String':
                  controlJson={
                    "type": "textBox",
                    "label": attr.AttributeLabel,
                    "name": attr.AttributeName,
                    "isMandatory":attr.IsMandatory
                  }
                
                  break;
                case 'Number':
                  controlJson={
                    "type": "number",
                    "label": attr.AttributeLabel,
                    "name": attr.AttributeName,
                    "isMandatory":attr.IsMandatory
                  }
                 
                  break;
                case 'One Of Many':
                  controlJson={
                    "type": "select",
                    "label": attr.AttributeLabel,
                    "options": attr.AttributeDataSet.split(','),
                    "name": attr.AttributeName,
                    "isMandatory":attr.IsMandatory
                  }
                 
                  break;
                case 'Many Of Many':
                  let dataSet = JSON.parse(attr.AttributeDataSet);
                  console.log(dataSet);
                  
                  controlJson={
                    "type": "multiSelect",
                    "label": attr.AttributeLabel,
                    "options": dataSet,
                    "name": attr.AttributeName,
                    "isMandatory":attr.IsMandatory,
                    "MultiSelectDropdownSettings" : {
                      "singleSelection": false,
                      "idField": "itemId",
                      "textField": "itemValue",
                      "selectAllText": "Select All",
                      "unSelectAllText": "UnSelect All",
                      "itemsShowLimit": 3,
                      "allowSearchFilter": false
                    }
                  }
                
                  break;
                case 'Rich Text':
                  controlJson={
                    "type": "textarea",
                    "label": attr.AttributeLabel,
                    "name": attr.AttributeName,
                    "isMandatory":attr.IsMandatory
                  }
               
                  break;
                case 'Attachment':
                  controlJson={
                    "type": "file",
                    "label": attr.AttributeLabel,
                    "name": attr.AttributeName,
                    "isMandatory":attr.IsMandatory
                  }
                
                  break;              
                default:
                  controlJson={
                    "type": "text",
                    "label": attr.AttributeLabel,
                    "name": attr.AttributeName,
                    "isMandatory":0
                  }
              }
              this.attributeSetData[i].Attributes[idx]=this.attributeSetList[a];
              this.attributeSetData[i].Controls[idx]=controlJson;
              if(this.resAttributeData){
                if(this.resAttributeData[i]["AttributeValues"][attr.AttributeName]!=undefined && this.resAttributeData[i]["AttributeValues"][attr.AttributeName]!=null && this.resAttributeData[i]["AttributeValues"][attr.AttributeName].length>0){
                  console.log(this.resAttributeData[i]["AttributeValues"][attr.AttributeName]);
                  let fileRouteLink:any, file:any;
                
                  if(attr.AttributeType=="Attachment"){
                    console.log(typeof(this.resAttributeData[i]["AttributeValues"][attr.AttributeName]));
                    
                    if(typeof(this.resAttributeData[i]["AttributeValues"][attr.AttributeName])=='object'){
                      fileRouteLink = environment.authApiBase+"/"+this.resAttributeData[i]["AttributeValues"][attr.AttributeName][0].fileName;
                      file = this.resAttributeData[i]["AttributeValues"][attr.AttributeName][0].fileName;  
                    }else{
                      fileRouteLink = environment.authApiBase+"/"+this.resAttributeData[i]["AttributeValues"][attr.AttributeName];
                      let filePathArray = this.resAttributeData[i]["AttributeValues"][attr.AttributeName].split('/'); 
                      file = filePathArray[filePathArray.length-1];
                    }
                   this.uploadedFiles.push({fileName:file, src : fileRouteLink});
                    this.attributeSetData[i].viewValues[idx] = {[attr.AttributeName]:file};             
                    this.attributeSetData[i].formValues[idx] = {[attr.AttributeName]:this.uploadedFiles};
                  }else if(attr.AttributeType=="Datetime"){
                    console.log(this.resAttributeData[i]["AttributeValues"][attr.AttributeName]);
                    
                    this.attributeSetData[i].viewValues[idx] = {[attr.AttributeName]:this.resAttributeData[i]["AttributeValues"][attr.AttributeName]!=''?this.formatDate(this.resAttributeData[i]["AttributeValues"][attr.AttributeName]):''};
                    this.attributeSetData[i].formValues[idx] = {[attr.AttributeName]:this.resAttributeData[i]["AttributeValues"][attr.AttributeName]};
                  }else if(attr.AttributeType=="Many Of Many"){
                    let itemList=[];
                    let selectedItemList = this.resAttributeData[i]["AttributeValues"][attr.AttributeName];
                    console.log(selectedItemList);
                    if(selectedItemList.length>0){
                      selectedItemList.forEach(element => {
                        itemList.push(element.itemValue);
                      });
                    }
                    
                    this.attributeSetData[i].viewValues[idx]={[attr.AttributeName]:itemList};
                    this.attributeSetData[i].formValues[idx]={[attr.AttributeName]:selectedItemList};
                  }else{
                    this.attributeSetData[i].viewValues[idx]={[attr.AttributeName]:this.resAttributeData[i]["AttributeValues"][attr.AttributeName]};
                    this.attributeSetData[i].formValues[idx]={[attr.AttributeName]:this.resAttributeData[i]["AttributeValues"][attr.AttributeName]};
                  }
                }else{
                  this.attributeSetData[i].viewValues[idx]={[attr.AttributeName]:''}
                  this.attributeSetData[i].formValues[idx]={[attr.AttributeName]:''}
                }            
              }else{
                this.attributeSetData[i].viewValues[idx]={[attr.AttributeName]:''}
                this.attributeSetData[i].formValues[idx]={[attr.AttributeName]:''}
              }
            
              idx++;
            }

          }
          if(i==(attributeSets.length-1)){
            for(let i=0;i<attributeSets.length;i++){
              this.attributeSetData[i].viewValues = (Object.assign({},...this.attributeSetData[i].viewValues));
              this.attributeSetData[i].formValues = (Object.assign({},...this.attributeSetData[i].formValues));

            }
          }
        }
       this.attributeValues = (Object.assign({},...this.attributeSetData[0].viewValues));     
      
       this.formTemplateList = this.attributeSetData;
     }
    });

  }

  isValidDate(dateStr) { 
    var parsedDate = Date.parse(dateStr);
    // return dateStr && Object.prototype.toString.call(dateStr) === "[object Date]" && !isNaN(dateStr);
    return isNaN(dateStr) && !isNaN(parsedDate);
  }

  formatDate(dateStr){
    console.log(dateStr)
      // // adjust 0 before single digit date
      // let date = ("0" + new Date(dateStr).getDate()).slice(-2);

      // // current month
      // let month = ("0" + (new Date(dateStr).getMonth() + 1)).slice(-2);
  
      // // current year
      // let year = new Date(dateStr).getFullYear();
  
      // prints date in YYYY-MM-DD format
      return(dateStr.day + "-" + dateStr.month + "-" + dateStr.year);
  }


  openModal(template,rec) {    
      this.selectedAttributeSetId = rec;
      let ngbModalOptions: NgbModalOptions = {
        size: "lg",
        backdrop: "static",
        keyboard: false,
        centered: true
      };
  
      this.modalService.open(template, ngbModalOptions);
      this.getDynamicForm(rec);  
  }

  getDynamicForm(rec){
    let group={};
    console.log(this.formTemplateList[rec].formValues);

    for(let i=0;i<this.formTemplateList[rec].Controls.length;i++){
      let input_template=this.formTemplateList[rec].Controls[i];   
      let input_value=this.formTemplateList[rec].formValues;      
      group[input_template.name]=new FormControl(input_value[input_template.name], input_template.isMandatory==1 ? Validators.required : []);  
    }
    this.dynamicFormGroup = new FormGroup(group);
    
    this.formTemplate = this.attributeSetData[rec].Controls;
  }

  submitAttributeValues(){
    let AttributeValuesJson=[];
     
    for(let i=0;i<this.attributeSetData.length;i++){
      console.log(this.attributeSetData[i].formValues);
      console.log(this.dynamicFormGroup.value);

      let res = Object.assign({},...this.attributeSetData[i].formValues);
      if(i==this.selectedAttributeSetId){
        AttributeValuesJson.push({SetName:this.attributeSetData[this.selectedAttributeSetId].SetName,AttributeValues:this.dynamicFormGroup.value})
      }
      else{
        AttributeValuesJson.push({SetName:this.attributeSetData[i].SetName,AttributeValues:Object.assign({},...this.attributeSetData[i].formValues)});
      }
    }
    
    
    let params={
      SetAssociatedWith:'Asset',
      AssetUUID: this.Id,
      AttributeData :JSON.stringify(AttributeValuesJson),
      UpdatedBy: JSON.parse(atob(localStorage.getItem('spUserData')))['UserUUID'],
    }
    
    this.customAttributesService.submitDynamicAttributesValues(params).subscribe(data=>{
      data['statusCode'] == 200 ? this.toastrService.success(data['message'], "Success") : this.toastrService.error(data['message'], '');
      this.getAttributeSet();
    });
    this.modalService.dismissAll();
    this.getAttributeSet();
  }
  

  detectFile(files, controlName){
    this.uploadedFiles=[];
    console.log(files[0]);
    // return;
    let reader = new FileReader(); 
    reader.readAsDataURL(files[0]); 
    reader.onloadend= (event:any)=>{
      this.uploadedFiles[0]=({'fileName': files[0].name,'src':event.target.result});  
      console.log(this.uploadedFiles[0]);
      
      this.dynamicFormGroup.patchValue({
        [controlName] : this.uploadedFiles[0]
      });   
      console.log(this.dynamicFormGroup.value);
    }      
  }

  onItemSelect(item: any){
    this.selectedItemIds.push(item);
    this.selectedItemIds=[...this.selectedItemIds];
  }
  onItemDeSelect(item: any){
    for(let i=0;i<this.selectedItemIds.length;i++){
      if(this.selectedItemIds[i].Id==item.Id)
        this.selectedItemIds.splice(i,1);
    }
    this.selectedItemIds=[...this.selectedItemIds];
  }
  

  onFocus(ele,mandatoryField){
    if(mandatoryField=='1'){
      this.requiredTextboxValue=true;
    }else{
      this.requiredTextboxValue=false;
    }
  }

  onBlur(ele,mandatoryField){
    if(mandatoryField=='1'){
      this.requiredTextboxValue=true;
    }else{
      this.requiredTextboxValue=false;
    }
  }


  removeFile(formControl){
    console.log(formControl); 
    this.uploadedFiles.splice(0, 1);
    // const btn = document.getElementById(`'${formControl}'`);
    // btn.style.display = 'none';
  }

  // $('#formDatepicker').datepicker(){
  //    "dateFormat" : "dd-mm-yyyy" //any valid format that you want to have
  // };
}
