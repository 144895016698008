import { Component, OnInit,ViewEncapsulation ,Output,EventEmitter, Input, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from "@angular/forms";
import { NgbDateAdapter, NgbDateStruct, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { RRule, RRuleSet, rrulestr } from 'rrule';
import { ThemeService } from 'ng2-charts';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Location } from "@angular/common";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-date-schedular',
  templateUrl: './date-schedular.component.html',
  styleUrls: ['./date-schedular.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }]
})
export class DateSchedularComponent implements OnChanges {
  schedulerForm: FormGroup;
  default:any;
  toDate:any;
  fromDate:any;
  frequency:any;
  pattern:any;
  dayOfWeek:any;
  dayOfMonth:any;
  monthOfYear:any;
  currentYear:any;
  days:any=[];
  byweekday=[];
  freq:any="";
  interval=0;
  repeatAfter:any;
  weekDay:any;
  monthDate:any;
  monthWeekNo:any;
  monthWeekday:any;
  yearMonth:any;
  yearDate:any;
  yearWeek:any;
  yearDay:any;
  bymonthday:any;
  bymonth:any;
  weekList=[{Id:1,WeekName:"First"},{Id:2,WeekName:"Second"},{Id:3,WeekName:"Third"},{Id:4,WeekName:"Fourth"},{Id:5,WeekName:"Fifth"}];
  weekDayList=[{Id:0, DayName:"Monday"}, {Id:1, DayName:"Tuesday"},{Id:2, DayName:"Wednesday"},{Id:3, DayName:"Thursday"},{Id:4, DayName:"Friday"},{Id:5, DayName:"Saturday"},{Id:6, DayName:"Sunday"}];
  monthList=[{Id:1, MonthName:"January"}, {Id:2, MonthName:"February"},{Id:3, MonthName:"March"},{Id:4, MonthName:"April"},{Id:5, MonthName:"May"},{Id:6, MonthName:"June"},{Id:7, MonthName:"July"},{Id:8, MonthName:"August"},{Id:9, MonthName:"September"},{Id:10, MonthName:"October"},{Id:11, MonthName:"November"},{Id:12, MonthName:"December"}];
  weekIds:any=[];
  wIds:any=[];
  weekDayIds:any=[];
  dayIds:any=[];
  monthIds:any=[];
  mIds:any=[];
  yrWeekId:any=[];
  yrWId:any=[];
  yrWeekDayIds:any=[];
  yrDayIds:any=[];
  monthYrOpt2Ids:any=[];
  mYrOpt2Ids:any=[];
  @Output() schedulerEvent = new EventEmitter<string>();
  @Input() viewFlag;
  @Input() scheduleDetails:any;
  checkDailyOpt:any=true;
  checkMonthlyOpt1:any=true;
  checkMonthlyOpt2:any=true;
  checkYearlyOpt1:any=true;
  checkYearlyOpt2:any=true;
  selectedMonthWeekNoItems:any;
  selectedMonthWeekItems:any;
  selectedOpt1YearMonthItems:any;
  selectedYearWeekNoItems:any;
  selectedYearWeekdayItems:any;
  selectedOpt2YearMonthItems:any;
  requiredField:any=false;
  constructor(private formBuilder: FormBuilder, private location: Location, public toastrService: ToastrService) { 
    this.schedulerForm = this.formBuilder.group({
      toDate:"" ,
      fromDate:"" ,
      frequency:"" ,
      dailyOpt:"",
      daily_NoOfDays:"",
      week_NoOfWeeks:"",
      dayOfWeekMon:"",
      dayOfWeekTues:"",
      dayOfWeekWed:"",
      dayOfWeekThur:"",
      dayOfWeekFri:"",
      dayOfWeekSat:"",
      dayOfWeekSun:"",
      monthOpt:"",
      monthDayNumber:"",
      monthGap:"",
      month_weekNum:[],
      month_weekDay:[],
      month_number:"",
      noOfYears:"",
      year_opt1Month:[],
      yearOpt:"",
      year_monthDay:"",
      year_weekNum:[],
      year_weekDay:[],
      year_opt2Month:[],
    });

  }

  weekListDropdownSettings: IDropdownSettings = {
    "singleSelection": false,
    "idField": "Id",
    "textField": "WeekName",
    "selectAllText": "Select All",
    "unSelectAllText": "UnSelect All",
    "itemsShowLimit": 3,
    "allowSearchFilter": false
  };
  weekDayListDropdownSettings: IDropdownSettings = {
    "singleSelection": false,
    "idField": "Id",
    "textField": "DayName",
    "selectAllText": "Select All",
    "unSelectAllText": "UnSelect All",
    "itemsShowLimit": 3,
    "allowSearchFilter": false
  };

  monthListDropdownSettings: IDropdownSettings = {
    "singleSelection": false,
    "idField": "Id",
    "textField": "MonthName",
    "selectAllText": "Select All",
    "unSelectAllText": "UnSelect All",
    "itemsShowLimit": 3,
    "allowSearchFilter": false
  };

  ngOnChanges() {
    // this.schedulerForm.controls["dayOfWeek"].setValue("MONDAY");
    // this.currentYear = new Date().getFullYear();
    // this.schedulerForm.controls["monthOfYear"].setValue(new Date().getMonth()+1<10?`0${new Date().getMonth()+1}`:new Date().getMonth()+1);
    // this.adjustDays(`${new Date().getMonth()+1}`);
    // this.schedulerForm.controls["dayOfMonth"].setValue(new Date().getDate());
    if(this.scheduleDetails) //existing schedule edit
      this.viewScheduleDetails(this.scheduleDetails);
    else{ // new schedule
      this.schedulerForm.controls["frequency"].setValue(this.viewFlag);
      this.setFrequencyType(this.viewFlag);
    }
  }

  // set default Week option on
  setFrequencyType(val){
    this.frequency=val;
  }

  // to save schedule pattern
  onOk(){
    
    let todaysDate = new Date();
    let startDate = this.schedulerForm.controls["fromDate"].value;
    let endDate = this.schedulerForm.controls["toDate"].value;
   // date validation
    if(startDate=="" || endDate==""){
      this.toastrService.info("Please select schedule start date and end date!","Warning");
      return;
    }
    // convert date into rrule format
    var sdt=new Date (startDate);
    var syear=sdt.getFullYear();
    var smonth=sdt.getMonth()+1;
    var sdate=sdt.getDate();
    var edt=new Date(endDate);
    var emonth=edt.getMonth()+1;
    var edate=edt.getDate();
    var eyear=edt.getFullYear();
  
    //push weekIds into array
    this.wIds=[];
    for (let i=0;i<this.weekIds.length;i++) {
     this.wIds.push(this.weekIds[i].Id);
    }
    // push weekdays into array for month option2
    this.dayIds=[];
    for (let i=0;i<this.weekDayIds.length;i++) {
      this.dayIds.push(this.weekDayIds[i].Id);
    }
    //push monthIds into array for year option1
    this.mIds=[];
    for (let i=0;i<this.monthIds.length;i++) {
      this.mIds.push(this.monthIds[i].Id);
    }
    //push year weekIds into array for year option2
    this.yrWId=[];
    for (let i=0;i<this.yrWeekId.length;i++) {
      this.yrWId.push(this.yrWeekId[i].Id);
    }
    //push year days into array for year option2
    this.yrDayIds=[];
    for (let i=0;i<this.yrWeekDayIds.length;i++) {
      this.yrDayIds.push(this.yrWeekDayIds[i].Id);
    }

    //push monthIds of option2 of years option2
    this.mYrOpt2Ids=[];
    for (let i=0;i<this.monthYrOpt2Ids.length;i++) {
      this.mYrOpt2Ids.push(this.monthYrOpt2Ids[i].Id);
    }
    this.yearDate=null;
    this.monthWeekNo=null;
    this.monthWeekday=null;
    this.monthDate=null;
    switch (this.frequency) {
      case 'D': //daily
        if(this.schedulerForm.controls.dailyOpt.value=="dailyOpt1"){
          this.repeatAfter = 1;
          this.weekDay = "0,1,2,3,4,5,6";
        }
        else if(this.schedulerForm.controls.dailyOpt.value=="dailyOpt2"){
          this.repeatAfter = this.schedulerForm.controls.daily_NoOfDays.value;
          this.weekDay =null;
        }else{
          this.toastrService.info("Please select at least one daily option!","Warning");
          return;
        }
        break;
      case 'W': //weekly
        this.repeatAfter=this.schedulerForm.controls.week_NoOfWeeks.value==""? 1:this.schedulerForm.controls.week_NoOfWeeks.value;
        let weeklyWeekday="";
        if(this.schedulerForm.controls.dayOfWeekMon.value==true)
          weeklyWeekday="0";
        if(this.schedulerForm.controls.dayOfWeekTues.value==true)
          weeklyWeekday=weeklyWeekday+"1";
        if(this.schedulerForm.controls.dayOfWeekWed.value==true)
          weeklyWeekday=weeklyWeekday+"2";
        if(this.schedulerForm.controls.dayOfWeekThur.value==true)
          weeklyWeekday=weeklyWeekday+"3";
        if(this.schedulerForm.controls.dayOfWeekFri.value==true)
          weeklyWeekday=weeklyWeekday+"4";
        if(this.schedulerForm.controls.dayOfWeekSat.value==true)
          weeklyWeekday=weeklyWeekday+"5";
        if(this.schedulerForm.controls.dayOfWeekSun.value==true)
          weeklyWeekday=weeklyWeekday+"6";
       if(weeklyWeekday==""){
        this.toastrService.info("Please select week and at least one day!","Warning");
        return;
       }
      this.weekDay =  weeklyWeekday.split("").join(",");
        break;
      case 'M': //monthly
        if(this.schedulerForm.controls.monthOpt.value=="monthOpt1"){
          this.monthDate = this.schedulerForm.controls.monthDayNumber.value.trim();
          this.repeatAfter=this.schedulerForm.controls.monthGap.value.trim();
          if(this.monthDate =="" || this.repeatAfter==""){
            this.toastrService.info("Please select day and month!","Warning");
          return;
          }
        }
        else if(this.schedulerForm.controls.monthOpt.value=="monthOpt2"){
          this.repeatAfter = this.schedulerForm.controls.month_number.value.trim();
          this.monthWeekNo = this.wIds.toString(); //this.schedulerForm.controls.month_weekNum.value;
          this.monthWeekday = this.dayIds.toString(); //this.schedulerForm.controls.month_weekDay.value;
          if(this.repeatAfter =="" || this.wIds.length<=0 || this.dayIds.length<=0){
            this.toastrService.info("Please select week, day and month!","Warning");
          return;
          }
        }else {
          this.toastrService.info("Please select at least one month option and details!","Warning");
          return;
         }
        break;
      case 'Y': //yearly
        this.repeatAfter=this.schedulerForm.controls.noOfYears.value.trim()=="" ? 1 : this.schedulerForm.controls.noOfYears.value.trim(); //pattern recurrence after year
        
        if(this.schedulerForm.controls.yearOpt.value=="yearOpt1"){
          this.yearMonth=this.mIds.toString(); //this.schedulerForm.controls.year_opt1Month.value;
          this.yearDate=this.schedulerForm.controls.year_monthDay.value;
          if(this.mIds.length<=0 || this.yearDate==""){
            this.toastrService.info("Please select year and date!","Warning");
            return;
          }
        }
        else if(this.schedulerForm.controls.yearOpt.value=="yearOpt2"){
          this.yearWeek=this.yrWId.toString(); //this.schedulerForm.controls.year_weekNum.value;
          this.yearDay= this.yrDayIds.toString(); //this.schedulerForm.controls.year_weekDay.value;
          this.yearMonth= this.mYrOpt2Ids.toString(); //this.schedulerForm.controls.year_opt2Month.value;
          if(this.yrWId.length<=0 || this.yrDayIds.length<=0 || this.mYrOpt2Ids.length<=0){
            this.toastrService.info("Please select week, day and month!","Warning");
            return;
          }
        }else{
          this.toastrService.info("Please select at least one year option and details!","Warning");
          return;
        }
        break;
    }
    let data = {
      "freq": this.frequency,
      "startDate":syear+"-"+smonth+"-"+sdate,
      "endDate":eyear+"-"+emonth+"-"+edate,
      "repeatAfter": this.repeatAfter,
      "weekDay": this.weekDay,
      "monthDate":this.monthDate,
      "monthWeek":this.monthWeekNo,
      "monthWeekDay":this.monthWeekday,
      "yearMonth":this.yearMonth,
      "yearDate":this.yearDate,
      "yearWeek":this.yearWeek,
      "yearDay":this.yearDay,
      "status":1
    }
    this.schedulerEvent.emit(JSON.stringify(data)); //data to parent component to save recurrence pattern
  }


  
  // adjustDays(event){
  //   this.days=[];
  //   let daysInMonth = new Date(this.currentYear, parseInt(event), 0).getDate();
  //   for(let i=1;i<=daysInMonth;i++){
  //     this.days.push(i);
  //   }
  // }

  onCancel(){
    this.location.back();
    // let data = {
    //   "freq": null,
    //   "repeatAfter": null,
    //   "weekday": null,
    //   "startDate":null,
    //   "endDate":null,
    // }
    // this.schedulerEvent.emit(JSON.stringify(data));
  }

  // select and deSelect of ng-multi-dropdown control items
  onWeekListSelect(item: any){
    this.weekIds.push(item);
    this.weekIds=[...this.weekIds];
  }
  onWeekListDeSelect(item: any){
    for(let i=0;i<this.weekIds.length;i++){
      if(this.weekIds[i].Id==item.Id)
        this.weekIds.splice(i,1);
    }
    this.weekIds=[...this.weekIds];
  }

  onWeekDayListSelect(item: any){
    this.weekDayIds.push(item);
    this.weekDayIds=[...this.weekDayIds];
  }

  onWeekDayListDeSelect(item: any){
    for(let i=0;i<this.weekIds.length;i++){
      if(this.weekDayIds[i].Id==item.Id)
        this.weekDayIds.splice(i,1);
    }
    this.weekDayIds=[...this.weekDayIds];
  }

  onMonthListYrOpt1Select(item: any){
    this.monthIds.push(item);
    this.monthIds=[...this.monthIds];
  }
  onMonthListYrOpt1DeSelect(item: any){
    for(let i=0;i<this.monthIds.length;i++){
      if(this.monthIds[i].Id==item.Id)
        this.monthIds.splice(i,1);
    }
    this.monthIds=[...this.monthIds];
  }

  onWeekListYrOpt2Select(item: any){
    this.yrWeekId.push(item);
    this.yrWeekId=[...this.yrWeekId];
  }
  onWeekListYrOpt2DeSelect(item: any){
    for(let i=0;i<this.yrWeekId.length;i++){
      if(this.yrWeekId[i].Id==item.Id)
        this.yrWeekId.splice(i,1);
    }
    this.yrWeekId=[...this.yrWeekId];
  }

  onWeekDayListYrOpt2Select(item: any){
    this.yrWeekDayIds.push(item);
    this.yrWeekDayIds=[...this.yrWeekDayIds];
  }

  onWeekDayListYrOpt2DeSelect(item: any){
    for(let i=0;i<this.yrWeekDayIds.length;i++){
      if(this.yrWeekDayIds[i].Id==item.Id)
        this.yrWeekDayIds.splice(i,1);
    }
    this.yrWeekDayIds=[...this.yrWeekDayIds];
  }

  onMonthListYrOpt2Select(item: any){
    this.monthYrOpt2Ids.push(item);
    this.monthYrOpt2Ids=[...this.monthYrOpt2Ids];
  }
  onMonthListYrOpt2DeSelect(item: any){
    for(let i=0;i<this.monthYrOpt2Ids.length;i++){
      if(this.monthYrOpt2Ids[i].Id==item.Id)
        this.monthYrOpt2Ids.splice(i,1);
    }
    this.monthYrOpt2Ids=[...this.monthYrOpt2Ids];
  }



  // function to view edited recurrence pattern
  viewScheduleDetails(data){
  
  let weekData=[], weekDayData=[], yrMonthData=[], yrWeekData=[], yrWeekDayData=[];
    if(data.monthWeek!=null){
      for(let i=0;i<data.monthWeek.split(",").length;i++){
        if(data.monthWeek.split(",")[i]==0)
          weekData.push({Id:0,WeekName:"first" });
        if(data.monthWeek.split(",")[i]==1)
          weekData.push({Id:1,WeekName:"second" });
        if(data.monthWeek.split(",")[i]==2)
          weekData.push({Id:2,WeekName:"third" });
        if(data.monthWeek.split(",")[i]==3)
          weekData.push({Id:3,WeekName:"fourth" }); 
        if(data.monthWeek.split(",")[i]==4)
          weekData.push({Id:4,WeekName:"last" });
      }
    }
    if(data.yearWeek!=null && data.yearWeek!=""){
      for(let i=0;i<data.yearWeek.split(",").length;i++){
        if(data.yearWeek.split(",")[i]==0)
          yrWeekData.push({Id:0,WeekName:"first" });
        if(data.yearWeek.split(",")[i]==1)
          yrWeekData.push({Id:1,WeekName:"second" });
        if(data.yearWeek.split(",")[i]==2)
          yrWeekData.push({Id:2,WeekName:"third" });
        if(data.yearWeek.split(",")[i]==3)
          yrWeekData.push({Id:3,WeekName:"fourth" }); 
        if(data.yearWeek.split(",")[i]==4)
          yrWeekData.push({Id:4,WeekName:"last" });
      }
    }
    
    if(data.yearMonth!=null && data.yearMonth!=""){
      for(let i=0;i<data.yearMonth.split(",").length;i++){
        if(data.yearMonth.split(",")[i]==0)
        yrMonthData.push({Id:0,MonthName:"January" });
        if(data.yearMonth.split(",")[i]==1)
        yrMonthData.push({Id:1,MonthName:"February" });
        if(data.yearMonth.split(",")[i]==2)
        yrMonthData.push({Id:2,MonthName:"March" });
        if(data.yearMonth.split(",")[i]==3)
        yrMonthData.push({Id:3,MonthName:"April" }); 
        if(data.yearMonth.split(",")[i]==4)
        yrMonthData.push({Id:4,MonthName:"May" });
        if(data.yearMonth.split(",")[i]==5)
        yrMonthData.push({Id:5,MonthName:"June" });
        if(data.yearMonth.split(",")[i]==6)
        yrMonthData.push({Id:6,MonthName:"July" });
        if(data.yearMonth.split(",")[i]==7)
        yrMonthData.push({Id:7,MonthName:"August" });
        if(data.yearMonth.split(",")[i]==8)
        yrMonthData.push({Id:8,MonthName:"September" }); 
        if(data.yearMonth.split(",")[i]==9)
        yrMonthData.push({Id:9,MonthName:"October" });
        if(data.yearMonth.split(",")[i]==10)
        yrMonthData.push({Id:10,MonthName:"November" });
        if(data.yearMonth.split(",")[i]==11)
        yrMonthData.push({Id:11,MonthName:"December" });
      }
    }
    if(data.yearDay!=null && data.yearDay!=""){
      for(let i=0;i<data.yearDay.split(",").length;i++){
        if(data.yearDay.split(",")[i]==0)
          yrWeekDayData.push({Id:0,DayName:"Monday" });
        if(data.yearDay.split(",")[i]==1)
          yrWeekDayData.push({Id:1,DayName:"Tuesday" });
        if(data.yearDay.split(",")[i]==2)
          yrWeekDayData.push({Id:2,DayName:"Wednesday" });
        if(data.yearDay.split(",")[i]==3)
          yrWeekDayData.push({Id:3,DayName:"Thursday" }); 
        if(data.yearDay.split(",")[i]==4)
          yrWeekDayData.push({Id:4,DayName:"Friday" });
        if(data.yearDay.split(",")[i]==5)
          yrWeekDayData.push({Id:4,DayName:"Saturday" });
        if(data.yearDay.split(",")[i]==6)
          yrWeekDayData.push({Id:4,DayName:"Sunday" });
      }
    }
    if(data.monthWeekDay!=null && data.monthWeekDay!=""){
      for(let i=0;i<data.monthWeekDay.split(",").length;i++){
        if(data.monthWeekDay.split(",")[i]==0)
          weekDayData.push({Id:0,DayName:"Monday" });
        if(data.monthWeekDay.split(",")[i]==1)
          weekDayData.push({Id:1,DayName:"Tuesday" });
        if(data.monthWeekDay.split(",")[i]==2)
          weekDayData.push({Id:2,DayName:"Wednesday" });
        if(data.monthWeekDay.split(",")[i]==3)
          weekDayData.push({Id:3,DayName:"Thursday" }); 
        if(data.monthWeekDay.split(",")[i]==4)
          weekDayData.push({Id:4,DayName:"Friday" });
        if(data.monthWeekDay.split(",")[i]==5)
          weekDayData.push({Id:4,DayName:"Saturday" });
        if(data.monthWeekDay.split(",")[i]==6)
          weekDayData.push({Id:4,DayName:"Sunday" });
      }
    }


    this.schedulerForm.controls['fromDate'].setValue(new Date(data.startDate));
    this.schedulerForm.controls['toDate'].setValue(new Date(data.endDate));
    this.schedulerForm.controls["frequency"].setValue(data.recurrenceFrequency);
    this.frequency=data.recurrenceFrequency;
    if(data.recurrenceFrequency=="D"){
      if(data.weekDay=="0,1,2,3,4,5,6")
        this.schedulerForm.controls.dailyOpt.setValue("dailyOpt1");
      else { 
        this.schedulerForm.controls.dailyOpt.setValue("dailyOpt2");    
        this.schedulerForm.controls.daily_NoOfDays.setValue(data.repeatAfter);
      }
    }else if(data.recurrenceFrequency=="W"){
      this.schedulerForm.controls.week_NoOfWeeks.setValue(data.repeatAfter);
      if(data.weekDay.includes("0"))
        this.schedulerForm.controls.dayOfWeekMon.setValue("MONDAY");
      if(data.weekDay.includes("1"))
        this.schedulerForm.controls.dayOfWeekTues.setValue("TUESDAY");
      if(data.weekDay.includes("2"))
        this.schedulerForm.controls.dayOfWeekWed.setValue("WEDNESDAY");
      if(data.weekDay.includes("3"))
        this.schedulerForm.controls.dayOfWeekThur.setValue("THURSDAY");
      if(data.weekDay.includes("4"))
        this.schedulerForm.controls.dayOfWeekFri.setValue("FRIDAY");
      if(data.weekDay.includes("5"))
        this.schedulerForm.controls.dayOfWeekSat.setValue("SATURDAY");
      if(data.weekDay.includes("6"))
        this.schedulerForm.controls.dayOfWeekSun.setValue("SUNDAY");
    }else if(data.recurrenceFrequency=="M"){
      if(data.monthWeek==null || data.monthWeek==""){
        this.schedulerForm.controls.monthOpt.setValue("monthOpt1")
        this.schedulerForm.controls.monthDayNumber.setValue(data.monthDate);
        this.schedulerForm.controls.monthGap.setValue(data.repeatAfter);
      }else{
        this.schedulerForm.controls.monthOpt.setValue("monthOpt2")
        this.weekIds=[...weekData];
        this.weekDayIds=[...weekDayData];
        this.schedulerForm.controls.month_weekNum.setValue([...weekData]);
        this.schedulerForm.controls.month_weekDay.setValue([...weekDayData]);
        this.schedulerForm.controls.month_number.setValue(data.repeatAfter);
      }
    }else if(data.recurrenceFrequency=="Y"){
      this.schedulerForm.controls.noOfYears.setValue(data.repeatAfter)
      if(data.yearWeek==null || data.yearWeek==""){
        this.schedulerForm.controls.yearOpt.setValue("yearOpt1")
        this.schedulerForm.controls.year_opt1Month.setValue([...yrMonthData]);
        this.schedulerForm.controls.year_monthDay.setValue(data.yearDate);
      }else{
        this.schedulerForm.controls.yearOpt.setValue("yearOpt2")
        this.schedulerForm.controls.year_weekNum.setValue([...yrWeekData]);
        this.schedulerForm.controls.year_weekDay.setValue([...yrWeekDayData]);
        this.schedulerForm.controls.year_opt2Month.setValue([...yrMonthData]);
      }
    }
  }



  // enable or disable of details of option controls selected in recurrence pattern
  DailyOptSel(e){
    if(e.target.value=="dailyOpt1"){
      this.checkDailyOpt=true;
      this.schedulerForm.controls.daily_NoOfDays.setValue("");
      this.schedulerForm.controls.daily_NoOfDays.disable();
    }
    else if(e.target.value=="dailyOpt2"){
      this.checkDailyOpt=false;
      this.schedulerForm.controls.daily_NoOfDays.enable();
      this.schedulerForm.controls.daily_NoOfDays.reset();
    }
  }

  MonthlyOptSel(e){
    if(e.target.value=="monthOpt1"){
      this.checkMonthlyOpt1=false;
      this.checkMonthlyOpt2=true;
      // this.schedulerForm.controls.month_weekDay.setValue("");
      // this.monthYrOpt2Ids=[{}];
      this.selectedMonthWeekNoItems=[];
      this.selectedMonthWeekItems=[];
      this.schedulerForm.controls.month_number.setValue("");
      }
      else if(e.target.value=="monthOpt2"){
        this.checkMonthlyOpt2=false;
        this.checkMonthlyOpt1=true;
        this.schedulerForm.controls.monthDayNumber.setValue("");
        this.schedulerForm.controls.monthGap.setValue("");
      }
  }

  YearlyOptSel(e){
    if(e.target.value=="yearOpt1"){
      this.checkYearlyOpt1=false;
      this.checkYearlyOpt2=true;  
      this.selectedYearWeekNoItems=[];
      this.selectedYearWeekdayItems=[];
      this.selectedOpt2YearMonthItems=[];
    }else if(e.target.value=="yearOpt2"){
      this.checkYearlyOpt1=true;
      this.checkYearlyOpt2=false;
      this.selectedOpt1YearMonthItems=[];
      this.schedulerForm.controls.year_monthDay.setValue("");
    }
  }

 
}
