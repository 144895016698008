import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()

export class JwtInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    var testUrl = "https://iot-api.corecotechnologies.com";
    let token = "";
    if (request.url.indexOf(testUrl) !== -1) {
      token = "";
    } else {
      let strToken = localStorage.getItem('token');
      token = JSON.parse(strToken);
    }
    // let strToken = localStorage.getItem('token');
    // let token = JSON.parse(strToken)
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `${token}`
        }
      });
    }
    return next.handle(request);
  }
}