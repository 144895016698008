import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-button-details',
  templateUrl: './button-details.component.html',
  styleUrls: ['./button-details.component.scss']
})
export class ButtonDetailsComponent implements OnInit {
  // @Input() hidden = true;
  @Output() valueChange = new EventEmitter();
  @Input() tabDetails;
  btnClass:any;
  btnLable:any;
  btnTitle:any;
  constructor() { }

  ngOnInit() {
    this.btnClass = this.tabDetails.icon;
    this.btnLable = this.tabDetails.label;
    this.btnTitle = this.tabDetails.title;
  }

  viewSites(){
    this.valueChange.emit(); 
  }

}
