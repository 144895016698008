import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })

export class OnTicketingService {
  constructor(public httpClient: HttpClient) { }

  getTicketList(params){
    return this.httpClient.post(environment.authApiBase + '/ticket/getTicketList', params, { withCredentials: false });
  }

  getTicketData(params){
    return this.httpClient.post(environment.authApiBase + '/ticket/getTicketData', params, { withCredentials: false });
  }
  getTicketAge(params){
    return this.httpClient.post(environment.authApiBase + '/ticket/getTicketAge', params, { withCredentials: false });
  }

  getTicketTTR(params){
    return this.httpClient.post(environment.authApiBase + '/ticket/getTicketTTR', params, { withCredentials: false });
  }

  getTicketCategoryList(SpUUID){
    return this.httpClient.post(environment.authApiBase + '/ticket/getTicketCategories', {SpUUID:SpUUID}, { withCredentials: false });
  }

  getTicketTypeMasterList(SpUUID){
    return this.httpClient.post(environment.authApiBase + '/ticket/getTicketTypes', {SpUUID:SpUUID}, { withCredentials: false });
  }

  getTicketSeverityList(){
    return this.httpClient.post(environment.authApiBase + '/ticket/getTicketSeverities','');
  }

  getTicketPrioritiesList(){
    return this.httpClient.post(environment.authApiBase + '/ticket/getTicketPriorities','');
  }

  getTicketStatusList(){
    return this.httpClient.post(environment.authApiBase + '/ticket/getTicketStatusList','');

  }

  getTicketAssginedToList(params){
    return this.httpClient.post(environment.authApiBase + '/site/getUserListBySite', params, { withCredentials: false });

  }

  ticketUpdateDetails(params){
    return this.httpClient.post(environment.authApiBase + '/ticket/getTicketLog', params, { withCredentials: false });

  }

  saveTicketDetails(record){
  
    return this.httpClient.post(environment.authApiBase + '/ticket/createTicket',record, { withCredentials: false });
  }

  updateTicketDetails(record){
    return this.httpClient.post(environment.authApiBase + '/ticket/updateTicket',record, { withCredentials: false });
  }

  getTicketInstanceDetails(record){
    return this.httpClient.post(environment.authApiBase + '/ticket/getTicketDetails',record, { withCredentials: false });
  }

  uploadImage(record){
    return this.httpClient.post(environment.authApiBase + '/ticket/uploadFile',record, { withCredentials: false });
  }

  getTicketTrend(params){
    return this.httpClient.post(environment.authApiBase + '/ticket/getTicketTrend', params, { withCredentials: false });
  }

  getTicketSubCounts(params){
    return this.httpClient.post(environment.authApiBase + '/ticket/getTicketSubCounts', params, { withCredentials: false });
  }

  createAssetSchedulerTicket(params){
    return this.httpClient.post(environment.authApiBase + '/ticket/createAssetSchedulerTicket',params, { withCredentials: false });
  }

  getExistingAssetTickets(params){
    return this.httpClient.post(environment.authApiBase + '/ticket/getExistingAssetTickets', params, { withCredentials: false });
  }
  // getTicketStatusList(){
  //   return this.httpClient.post(environment.authApiBase + '/ticket/getTicketStatusList','');
  // }

  // getTicketMasterRecords(){
  //   return this.httpClient.post(environment.authApiBase + '/ticket/getTicketMasterRecords','');
  // }

  // updateTicketMasterList(){
  //   return this.httpClient.post(environment.authApiBase + '/ticket/updateTicketMasterList','');
  // }

  // addTicketMasterRecord(){
  //   return this.httpClient.post(environment.authApiBase + '/ticket/addTicketMasterRecord','');
  // }

}