import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })


export class AssetManagementService {

  constructor(public httpClient: HttpClient) { }

  //to get listing of assets
  getListAsset(reqData) {
    return this.httpClient.post(environment.authApiBase + '/asset/getListAsset', reqData, { withCredentials: false });
  }

  //to get details of asset for specific ID
  getDetailsAsset(assetId) {
    return this.httpClient.post(environment.authApiBase + '/asset/getDetailsAsset', { assetId: assetId }, { withCredentials: false });
  }

  //to get listing of asset contact details
  getAssetContactDetails(assetId) {
    return this.httpClient.post(environment.authApiBase + '/asset/getAssetContactDetails', { assetId: assetId }, { withCredentials: false });

  }

  //to display qr code
  displayQrCode(assetId) {
    return this.httpClient.post(environment.authApiBase + '/asset/displayQrCode', { assetId: assetId }, { withCredentials: false });
  }

  generateQRCodeAssets(ids) {
    return this.httpClient.post(environment.authApiBase + '/asset/generateAllAssetQRCode', ids, { withCredentials: false });

  }

  //to save asset details
  addAssetDetails(assetDetails) {
    return this.httpClient.post(environment.authApiBase + '/asset/addAssetDetails', assetDetails, { withCredentials: false });
  }

  //to update asset details
  updateAssetDetails(assetDetails,photos,existingPhotos) {
    return this.httpClient.post(environment.authApiBase + '/asset/updateAssetInstanceDetails',{assetDetails,photos,existingPhotos}, { withCredentials: false });
  }

  //to save asset details
  updateAssetInstanceDetails(assetDetails) {
    return this.httpClient.post(environment.authApiBase + '/asset/updateAssetInstanceDetails', assetDetails, { withCredentials: false });
  }

  //to display qr code
  generateQRCode(assetId) {
    return this.httpClient.post(environment.authApiBase + '/asset/generateQRCode', { assetId: assetId }, { withCredentials: false });
  }


  //to saubmit contact details - add/update
  submitContactDetails(data, addUpdateFlag) {

    if (addUpdateFlag == 'Save')
      return this.httpClient.post(environment.authApiBase + '/asset/saveAssetContact', data);

    if (addUpdateFlag == 'Update')
      return this.httpClient.post(environment.authApiBase + '/asset/updateAssetContact', data);

  }



  getAssetCategories() {
    return this.httpClient.post(environment.authApiBase + '/asset/getAssetCategories', { withCredentials: false });
  }

  getAssetStatus() {
    return this.httpClient.post(environment.authApiBase + '/asset/getAssetStatus', { withCredentials: false });
  }
  
  getAssetTypes(instanceId) {
    return this.httpClient.post(environment.authApiBase + '/asset/getAssetTypes', { instanceId: instanceId }, { withCredentials: false });
  }

  getStdChecklists(category, type) {
    return this.httpClient.post(environment.authApiBase + '/asset/getStdChecklists', { categoryInstanceId: category, typeInstanceId: type }, { withCredentials: false });
  }

  getPPMChecklists(category, type) {
    return this.httpClient.post(environment.authApiBase + '/asset/getPPMChecklists', { categoryInstanceId: category, typeInstanceId: type }, { withCredentials: false });
  }

  getStdLogsheets(category, type) {
    return this.httpClient.post(environment.authApiBase + '/asset/getStdLogsheets', { categoryInstanceId: category, typeInstanceId: type }, { withCredentials: false });
  }

  getAssetContactTypes() {
    return this.httpClient.post(environment.authApiBase + '/asset/getAssetContactTypes', { withCredentials: false });
  }

  getAssetContactLevels() {
    return this.httpClient.post(environment.authApiBase + '/asset/getAssetContactLevels', { withCredentials: false });
  }

  getAMCDetails(AssetUUID) {
    return this.httpClient.post(environment.authApiBase + '/asset/AMCDetailsList', { AssetUUID }, { withCredentials: false });
  }

  getVendorList() {
    return this.httpClient.post(environment.authApiBase + '/asset/vendorList', {}, { withCredentials: false });
  }

  submitAMCRecord(data: any) {
    return this.httpClient.post(environment.authApiBase + '/asset/submitAMCRecord', data, { withCredentials: false });
  }

  getJobHistroyList(AssetUUID) {
    return this.httpClient.post(environment.authApiBase + '/asset/jobHistoryList', { AssetUUID }, { withCredentials: false });
  }

  getJobTypeList() {
    return this.httpClient.post(environment.authApiBase + '/asset/jobTypeList', { withCredentials: false });
  }

  submitJobHistroy(data: any) {
    return this.httpClient.post(environment.authApiBase + '/asset/submitJobHistory', data, { withCredentials: false });
  }

  saveSchedule(scheduleDetails, assetId, userId){
    return this.httpClient.post(environment.authApiBase + '/schedule/saveSchedule', {scheduleDetails, assetId, userId}, { withCredentials: false });
  }

  getSchedule(req){
    return this.httpClient.post(environment.authApiBase + '/schedule/getSchedule', req, { withCredentials: false });
  }

  getPPMDetails(AssetUUID) {
    return this.httpClient.post(environment.authApiBase + '/asset/getPPMDetails', { AssetUUID }, { withCredentials: false });
  }

  getPPMExecDate(AssetUUID) {
    return this.httpClient.post(environment.authApiBase + '/asset/getPPMExecDate', { AssetUUID }, { withCredentials: false });
  }
  
  getPPMSchedule(AssetUUID) {
    return this.httpClient.post(environment.authApiBase + '/asset/getPPMSchedule', { AssetUUID }, { withCredentials: false });
  }

  getAssetPPMTickets(AssetUUID){
    return this.httpClient.post(environment.authApiBase + '/asset/getAssetPPMTickets', { AssetUUID }, { withCredentials: false });
  }

  getParentAssets(params){
    return this.httpClient.post(environment.authApiBase + '/asset/getParentAssets', params, { withCredentials: false });
  }

  getSiteUsers(params){
    return this.httpClient.post(environment.authApiBase + '/user/getSiteUsers', params, { withCredentials: false });
  }

  updateAssetMetadata(params){
    return this.httpClient.post(environment.authApiBase + '/asset/updateAssetMetadata', params, { withCredentials: false });
  }

  getAssetMetadata(params){
    return this.httpClient.post(environment.authApiBase + '/asset/getAssetMetadata', params, { withCredentials: false });

  }
}