import { Pipe, PipeTransform, ChangeDetectorRef } from "@angular/core";

@Pipe({
    name: "UIService"
})

export class UIServicePipe implements PipeTransform {
    constructor() { }
    transform(terminalImage: any): any {


        return terminalImage.replace("₹", "");
    }


}