import { NgModule } from "@angular/core";
import { RouterModule, Routes, PreloadAllModules } from "@angular/router";
import { ContentLayoutComponent } from "./shared/components/layout/content-layout/content-layout.component";
import { content } from "./shared/routes/content-routes";
import { AdminGuard } from "./shared/guard/admin.guard";
import { AuthGuard } from "./_helpers/auth.guard";
import { RoleGuard } from "./_helpers/rbac/role-guard";



const routes: Routes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  { path: "", loadChildren: "./simba-quicker/simba-quicker.module#SimbaQuickerModule"}, 
  { path: "", loadChildren: "./auth/prelogin.module#PreLoginModule" }, //"./auth/login/login.module#LoginModule"   {path :"login"}
  {
    path: "",
    component: ContentLayoutComponent,
    canActivate: [AuthGuard, RoleGuard],
    children: content
  },
  { path: "**", redirectTo: "/login" }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: "enabled",
      scrollPositionRestoration: "enabled",
      preloadingStrategy: PreloadAllModules
    }),
    // RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
