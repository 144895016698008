import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({ providedIn: 'root' })


export class UIUtilService {

    constructor(public httpClient: HttpClient) { }


    //this function is used all over the system from any view
    //it returns the class as per the flag i.e. (status)
    DynamicClassMethod(status) {
        //switch for flag
        switch (status) {
            case "1":
                // Status == Successful (Green)
                return "fa fa-circle green-dot-circle";
            case "2":
                // Status == Failed (Red)
                return "fa fa-circle red-dot-circle";
            case "0":
                // Status == Not Executed/Found (Grey)
                return "fa fa-circle grey-dot-circle";
            case "":
                // Status == Not Executed/Found (Grey)
                return "fa fa-circle grey-dot-circle";
            case "YES":
                // Status == Answer is yes for checklist question- green color class -success
                return "badge badge-success badge-pill digits";
            case "NO":
                // Status == Answer is no for checklist question - red color class -danger
                return "badge badge-danger badge-pill digits";
            case "statusYesQus":
                // Status == Answer is yes for checklist question- no danger class is applied
                return "list-group-item d-flex justify-content-between align-items-center";
            case "statusNoQus":
                // Status == Answer is no for checklist question -  danger class is applied
                return "list-group-item d-flex justify-content-between align-items-center txt-danger";

            case "logsheetQuestion":
                //this class will be static for all records
                return "list-group-item d-flex justify-content-between align-items";
            /*default:
                return "list-group-item d-flex justify-content-between align-items";*/
        }
    }


    //to replace '/' to '-' in dateformat
    replaceDateSpecialChar(date) {
        date = date.split("/").reverse().join("/");
        date = date.replace(/[^a-zA-Z0-9]/g, '-');
        return date;
    }

    //to get in yyyy/dd/mm
    getInYMD(incomingDate) {
        var date = new Date(incomingDate),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }

    tabButtonDetails(event) {
        switch (event) {
            case "tab-AssetSpecifications":
            case "tab-AssetSheet":
                return { icon: 'fa fa-edit', label: 'Edit', title: 'Edit Asset' };
            case "tab-AssetAmc":
                return { icon: 'fa fa-list', label: 'Details', title: 'AMC Details' };
            case "tab-AssetContacts":
                return { icon: 'fa fa-list', label: 'Details', title: 'Contact Details' };
            case "tab-AssetHistory":
                return { icon: 'fa fa-list', label: 'Details', title: 'Job History Details' };
            case "tab-ClientsSites":
                return { icon: 'fa fa-list', label: 'Details', title: 'Sites Details' };
            case "tab-ClientsUser":
                return { icon: 'fa fa-list', label: 'Details', title: 'Users Details' };

        }
    }

    //togo back to last page
    gobackToPreviousPage() {
        setInterval(() => {
            history.go(-2);
        }, 4000);
    }

    //as per roles it will send true or false for few pages -in short ataz
    makingSecure(fromView) {
        if (fromView.flag == 'sites' && (fromView.uRoleId == 8 || fromView.uRoleId == 10)) {
            return "unClickable";
        }
        else if (fromView.flag == 'properties' && (fromView.uRoleId == 1 || fromView.uRoleId == 2)) {
            return "unClickable";
        }
        else {
            return "hyperlinkView";
        }


    }

}