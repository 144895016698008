import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({ providedIn: 'root' })


export class dateTimeUtilService {
    monthNames: any = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    constructor(public httpClient: HttpClient) { }

    //to get days of a specific month-year
    getDaysArrayOfMonth(year, month) {

        //to get in this format "Sun Sep 01 2019 00:00:00" starting from 1st date of month
        const date = new Date(year, month - 1, 1);
        //30 days pushed in this array
        const result = [];
        
        while (date.getMonth() == month - 1) {
            if (date.getMonth() + 1 < 10) {
                result.push(
                    `${date.getFullYear() +
                    "/0" +
                    (date.getMonth() + 1) +
                    "/" +
                    (date.getDate() < 10 ? '0' + date.getDate() : date.getDate())}`
                );
            } else {
                result.push(
                    `${date.getFullYear() +
                    "/" +
                    (date.getMonth() + 1) +
                    "/" +
                    (date.getDate() < 10 ? '0' + date.getDate() : date.getDate())
                  }`
                );
            }
            date.setDate(date.getDate() + 1);
        }

        return result;
    }


    // Return array of last30days from current date (Today)
    getLast30Days() {

        //to get count of days for last 30 days
        var priorDate = new Date();
        const last30days = [];
        for (var i = 0; i < 30; i++) {
            var days = priorDate.getDate() < 10 ? '0' + priorDate.getDate() : priorDate.getDate();
            var months = (priorDate.getMonth() + 1) < 10 ? '0' + (priorDate.getMonth() + 1) : (priorDate.getMonth() + 1);

            last30days.push(
                priorDate.getFullYear() +
                "/" +
                months +
                "/" +
                days
            );
            priorDate.setDate(priorDate.getDate() - 1);
        }

        /**
         * for (var i = 0; i < 30; i++) {
            last30days.push(
                priorDate.getFullYear() +
                "/" +
                (priorDate.getMonth() + 1) +
                "/" +
                priorDate.getDate()
            );
            priorDate.setDate(priorDate.getDate() - 1);
        }
         */

        return last30days;
    }


    //to get financial year, last 5 from current year
    getFinancialYear() {
        var DateObject = new Date();
        var currentMonth = DateObject.getMonth() + 1;
        const financialYear = [];

        //if current month less than april
        if (currentMonth < 4) {
            var source = 1;
            var destination = 0
            for (var i = 0; i < 5; i++) {
                financialYear.push({
                    name: "FY  " + ((DateObject.getFullYear() - source)) + "-" + parseInt(JSON.stringify((DateObject.getFullYear() - destination)).substring(2)),
                    value: ((DateObject.getFullYear() - source))
                });
                source++;
                destination++
            }
        }
        //if current month greater-equal than/to april
        if (currentMonth >= 4) {
            var source = 0;
            var destination = -1
            for (var i = 0; i < 5; i++) {
                financialYear.push({
                    name: "FY  " + ((DateObject.getFullYear() - source)) + "-" + parseInt(JSON.stringify((DateObject.getFullYear() - destination)).substring(2)),
                    value: ((DateObject.getFullYear() - source))
                });
                source++;
                destination++
            }
        }

        //it will last 5 years in year to year format
        return financialYear;
    }

    //to get only current month in int/digit
    getCurrentDateNumber() {
        var today = new Date();
        return today.getDate();
    };

    getMonthsArray(no_of_months) {
        let today = new Date();
        let lastMonths = [];
        for (let i = 0; i < no_of_months; i++) {
            lastMonths.push({
                id: today.getFullYear().toString() + '-' + (today.getMonth() + 1),
                monthName: this.monthNames[today.getMonth()] + ' - ' + today.getFullYear().toString()
            });
            today.setMonth(today.getMonth() - 1);
        }
        return lastMonths;
    }

    //to get days of a specific month-year
    getDaysArray(year, month) {

        //to get in this format "Sun Sep 01 2019 00:00:00" starting from 1st date of month
        const date = new Date(year, month - 1, 1);
        //30 days pushed in this array
        const result = [];
        while (date.getMonth() == month - 1) {

            var days = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()

            if (date.getMonth() + 1 < 10) {

                result.push(
                    `${date.getFullYear() +
                    "/0" +
                    (date.getMonth() + 1) +
                    "/" +
                    days}`
                );
            } else {
                result.push(
                    `${date.getFullYear() +
                    "/" +
                    (date.getMonth() + 1) +
                    "/" +
                    days}`
                );
            }
            date.setDate(date.getDate() + 1);
        }

        return result;
    }

    getDateList(startDate, endDate) {
        
        let listDate = [];
        let dateMove = new Date(startDate);
        let strDate = startDate;

        while (strDate < endDate) {
            strDate = dateMove.toISOString().slice(0, 10);
            let dd = (dateMove.getDate() < 10 ? '0' : '') + dateMove.getDate();
            let MM = ((dateMove.getMonth() + 1) < 10 ? '0' : '') + (dateMove.getMonth() + 1);
            listDate.push(dateMove.getFullYear() + "/" + MM + "/" + dd);
            dateMove.setDate(dateMove.getDate() + 1);
        };

        return listDate;
    }

    //to get todays dateTime
    todaysCurrentDateTime() {
        var today = new Date();
        var currentDate =
            today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
        var currentTime =
            today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        var currentDateTime = currentDate + " " + currentTime;

        return currentDateTime;
    };
    //to get todays date
    todaysCurrentDate() {
        var today = new Date();
        var currentDate =
            today.getFullYear() + "/" + (today.getMonth() + 1) + "/" + today.getDate();

        var currentDate = currentDate;

        return currentDate;
    };

    

    prevDate(date){
        let month = date.getMonth();
        let year = date.getFullYear();
        let day = date.getDate();
        const prevDate = new Date(year, month, day - 1);
        return prevDate;
    }

    nextDate(date){
        let month = date.getMonth();
        let year = date.getFullYear();
        let day = date.getDate();
        const nextDate = new Date(year, month, day + 1);
        return nextDate; 
    }

    prevWeek(date){
        let beforeOneWeek = new Date(date.getTime() - 60 * 60 * 24 * 7 * 1000)
        , day = beforeOneWeek.getDay()
        , diffToMonday = beforeOneWeek.getDate() - day + (day === 0 ? -6 : 1)
        // , lastMonday = new Date(beforeOneWeek.setDate(diffToMonday))
        , lastSunday = new Date(beforeOneWeek.setDate(diffToMonday + 6));

        var curr = beforeOneWeek; // get current date
        var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
        var last = first + 6; // last day is the first day + 6
        var firstWeekday = new Date(beforeOneWeek);
        var lastWeekday = new Date(curr.setDate(last));
        var weekDayRange = {"firstWeekday" :firstWeekday, "lastWeekday": lastWeekday};
         return weekDayRange;
    }

    currentWeek(date){

    var curr = date; // get current date
    var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
    var last = first + 6; // last day is the first day + 6
    var firstWeekday = new Date(curr.setDate(first));
    var lastWeekday = new Date(curr.setDate(last));
    var weekDayRange = {"firstWeekday" :firstWeekday, "lastWeekday": lastWeekday};
    return weekDayRange;

    }

    nextWeek(date){
        let beforeOneWeek = new Date(date.getTime() + 60 * 60 * 24 * 7 * 1000)
        , day = beforeOneWeek.getDay()
        , diffToMonday = beforeOneWeek.getDate() - day + (day === 0 ? -6 : 1)
        // , lastMonday = new Date(beforeOneWeek.setDate(diffToMonday))
        , lastSunday = new Date(beforeOneWeek.setDate(diffToMonday + 6));

        var curr = beforeOneWeek; // get current date
        var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
        var last = first + 6; // last day is the first day + 6
        var firstWeekday = new Date(beforeOneWeek);
        var lastWeekday = new Date(curr.setDate(last));
        var weekDayRange = {"firstWeekday" :firstWeekday, "lastWeekday": lastWeekday};
        return weekDayRange;
    }

    prevMonth(date){
      let beforeOneMonth = new Date(date.getFullYear(), date.getMonth()-1, 1);
      return beforeOneMonth;
    }

    nextMonth(date){
        let beforeOneMonth = new Date(date.getFullYear(), date.getMonth()+1, 1);
        return beforeOneMonth;
    }

    getDayOfWeek(startDate, endDate) {

        var arr = new Array();
       let start = new Date(startDate)
       let end =new Date(endDate)
       while (start <= end) {
         arr.push(new Date(start));
         start.setDate(start.getDate() + 1);
       }
    
       return arr; 
    }


}
