import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  yearSpan:any;
  constructor() { }

  ngOnInit() { 
    var currentYear =  (new Date()).getFullYear();   
    var twoLastDigits = currentYear%100;
    var formatedTwoLastDigits = "";

    if (twoLastDigits <10 ) {
      formatedTwoLastDigits = "0" + twoLastDigits;
    } else {
      formatedTwoLastDigits = "" + twoLastDigits;
    }
      this.yearSpan = currentYear+"-"+(parseInt(formatedTwoLastDigits)+1);
  }

}
