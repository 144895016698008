import { Pipe, PipeTransform, ChangeDetectorRef } from "@angular/core";

@Pipe({
    name: "customPipe"
})

export class CustomPipe implements PipeTransform {
    constructor(private cd: ChangeDetectorRef) { }
    transform(data: any, columnFlags?: any): any {
        //used in case of tdm dashboard of checklist
        if (columnFlags == 1) {
            if (data) {
                return data['status']
            }

            if (data == undefined) {
                return "";
            }
            else {
                return ""
            }
        }

        //dynamic class handling for checklistInstance Data for answer
        //Class handled dynamically as per answer for question text via custom pipe
        if (columnFlags == 3) {
            //ans is yes, sending flag for UI util services
            if (data == "YES") {
                return "statusYesQus"
            }
            //ans is no ,sending flag for UI util services
            if (data == "NO") {
                return "statusNoQus";
            }

        }


        if (columnFlags == 2) {


        }




    }


}