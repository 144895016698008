import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders  } from "@angular/common/http";
import { environment } from '../../../environments/environment';
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({ providedIn: 'root' })


export class CoreObjectService {

    constructor(public httpClient: HttpClient) { }

    //to get listing of clients in dropdown - API
    getClietlist(spUuid) {
        return this.httpClient.post(environment.authApiBase + '/import/getClietlist', { spUuid: spUuid }, { withCredentials: false });
    }

    //to get listing of site in dropdown when client is selected- API
    getSitelist(clientId) {
        return this.httpClient.post(environment.authApiBase + '/import/getSitelist', { clientId: clientId }, { withCredentials: false });
    }
    /**
    * To download excel template 
    * @param POST Template name to download 
    * @return blob on success, err on failure
    * @author Shubham Deshmukh <shubham.deshmukh@corecotechnologies.com>
    * @since v1.0 <date: 04 Dec 2019>
    */
    downloadTemplate(templateName) : Observable<Blob>{
        return this.httpClient.post(environment.authApiBase + '/import/downloadTemplate',{ templateName: templateName }, {responseType: 'blob'}).pipe(map(response => {
          return response;
        }));
      };
    saveBuildingData(data){
      //return this.httpClient.post(environment.authApiBase + '/import/saveBuildingData',data,{ withCredentials: false });
      let promise = new Promise((resolve, reject) => {
        this.httpClient.post(environment.authApiBase + '/import/saveBuildingData', data, { withCredentials: false })
          .toPromise()
          .then(
            res => { // Success
              // this.results = res.json().results;
              console.log("Promise Resolved");
              resolve(res);
            },
            msg => { // Error
              reject(msg);
            }
          );
      });
      return promise;
    }
    generateUniqueBuildingUUID(data){
      //return this.httpClient.post(environment.authApiBase + '/import/generateUniqueBuildingUUID',data,{ withCredentials: false });
      let promise = new Promise((resolve, reject) => {
        //let apiURL = `${this.apiRoot}?term=${term}&media=music&limit=20`;
        this.httpClient.post(environment.authApiBase + '/import/generateUniqueBuildingUUID', data, { withCredentials: false })
          .toPromise()
          .then(
            res => { // Success
              // this.results = res.json().results;
              console.log("Promise Resolved");
              resolve(res);
            },
            msg => { // Error
              reject(msg);
            }
          );
      });
      return promise;
    }

    generateClientUUID(data): any {
      let promise = new Promise((resolve, reject) => {
        //let apiURL = `${this.apiRoot}?term=${term}&media=music&limit=20`;
        this.httpClient.post(environment.authApiBase + '/client/generateClientUUID', data, { withCredentials: false })
          .toPromise()
          .then(
            res => { // Success
              // this.results = res.json().results;
              console.log("Promise Resolved");
              resolve(res);
            },
            msg => { // Error
              reject(msg);
            }
          );
      });
      return promise;
    }

    saveClientData(data){
    // return this.httpClient.post(environment.authApiBase + '/client/saveClientData', data, { withCredentials: false });
     let promise = new Promise((resolve, reject) => {
      //let apiURL = `${this.apiRoot}?term=${term}&media=music&limit=20`;
      this.httpClient.post(environment.authApiBase + '/client/saveClientData', data, { withCredentials: false })
        .toPromise()
        .then(
          res => { // Success
            // this.results = res.json().results;
            console.log("Promise Resolved");
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
    }

    saveUnitData(data): any {
      let promise = new Promise((resolve, reject) => {
        //let apiURL = `${this.apiRoot}?term=${term}&media=music&limit=20`;
        this.httpClient.post(environment.authApiBase + '/softServices/saveUnitData', data, { withCredentials: false })
          .toPromise()
          .then(
            res => { // Success
              // this.results = res.json().results;
              console.log("Promise Resolved");
              resolve(res);
            },
            msg => { // Error
              reject(msg);
            }
          );
      });
      return promise;
    }

    submitLogsheet(dataExl){
      return this.httpClient.post(environment.authApiBase + '/import/submitLogsheet', dataExl, { withCredentials: false })
    }

    
    bulkSubmitUserAttendance(data): any {
        return this.httpClient.post(environment.authApiBase + '/userAttendance/bulkSubmitUserAttendance', data, { withCredentials: false });
    }
          
}