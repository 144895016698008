import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })


export class TDMSheetsService {
    body: any;

    constructor(public httpClient: HttpClient) { }

    getstandardChecklist(record){
        this.body = {spuuid: record, }
        return this.httpClient.post(environment.authApiBase + '/tdmSheets/standardChecklist',this.body);
    }

    
    getstandardLogsheet(record){
        this.body = {spuuid: record,}
        return this.httpClient.post(environment.authApiBase + '/tdmSheets/standardLogsheet',this.body);
    }

    getChecklistDisplayRecord(value){
        this.body = {Id: value}
        return this.httpClient.post(environment.authApiBase + '/tdmSheets/checklistDisplayRecord',this.body);
    }

    getLogsheetDisplayRecord(value){
        this.body = {Id: value}
        return this.httpClient.post(environment.authApiBase + '/tdmSheets/logsheetDisplayRecord',this.body);
    }

    
    


}