import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomAttributesService {

  constructor(public httpClient: HttpClient) { }

  submitCustomAttributeHeader(params){  
    return this.httpClient.post(environment.authApiBase + '/customAttributes/submitCustomAttributeHeader', params, { withCredentials: false });
  }

  getCustomAttributesList(Id){    
    return this.httpClient.post(environment.authApiBase + '/customAttributes/getCustomAttributesList',{Id:Id}, { withCredentials: false });
  }

  getCustomAttributesHeader(Id){
    return this.httpClient.post(environment.authApiBase + '/customAttributes/getCustomAttributesHeader',{Id:Id}, { withCredentials: false });
  }
  getAttributeInstanceDetails(params){
    return this.httpClient.post(environment.authApiBase + '/customAttributes/getAttributeInstanceDetails', params, { withCredentials: false });
  }

  updateCustomAttribute(params){
    return this.httpClient.post(environment.authApiBase + '/customAttributes/updateCustomAttribute', params, { withCredentials: false });
  }

  updateCustomAttributeHeader(params){
    return this.httpClient.post(environment.authApiBase + '/customAttributes/updateCustomAttributeHeader', params, { withCredentials: false });
  }

  submitDynamicAttributesValues(params){
    return this.httpClient.post(environment.authApiBase + '/customAttributes/submitDynamicAttributesValues', params, { withCredentials: false });
  }

  getDynamicAttributeValues(params){
    return this.httpClient.post(environment.authApiBase + '/customAttributes/getDynamicAttributeValues', params, { withCredentials: false });
  }

  deleteCustomAttribute(params){
    return this.httpClient.post(environment.authApiBase + '/customAttributes/deleteCustomAttribute', params, { withCredentials: false });
  }

  getAttributeSets(ClientId){
    return this.httpClient.post(environment.authApiBase + '/customAttributes/getAttributeSets', {ClientUUID:ClientId}, { withCredentials: false });
  }

  getCoreObjects(){
    return this.httpClient.post(environment.authApiBase + '/customAttributes/getCoreObjects', null, { withCredentials: false });
  }

  getAttributeSetStatus(){
    return this.httpClient.post(environment.authApiBase + '/customAttributes/getAttributeSetStatus', null, { withCredentials: false });
  }

  submitCustomAttribute(params){
    return this.httpClient.post(environment.authApiBase + '/customAttributes/submitCustomAttribute', params, { withCredentials: false });
  }

  updateDynamicAttributesSequence(list){
    return this.httpClient.post(environment.authApiBase + '/customAttributes/updateDynamicAttributesSequence', list, { withCredentials: false });
  }

  getAttributeSetBySiteId(params){
    return this.httpClient.post(environment.authApiBase + '/customAttributes/getAttributeSetBySiteId', params, { withCredentials: false });
  }
}
