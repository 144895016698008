import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })

export class MMRConfigurationService {
  constructor(public httpClient: HttpClient) { }

  // get asset question for MMR Configuration
  getAssetAttributes(reqData) {
    return this.httpClient.post(environment.authApiBase + '/MMRConfiguration/getAssetAttributes', reqData, { withCredentials: false });
  }

  getAttributePatternTypes(){
    return this.httpClient.post(environment.authApiBase + '/MMRConfiguration/getAttributePatternTypes', { withCredentials: false });
  }
  
  getOperators(){
    return this.httpClient.post(environment.authApiBase + '/MMRConfiguration/getOperators', { withCredentials: false });
  }
  
  getChartTypes(){
    return this.httpClient.post(environment.authApiBase + '/MMRConfiguration/getChartTypes', { withCredentials: false });
  }

  saveMMRConfiguration(params){
    return this.httpClient.post(environment.authApiBase + '/MMRConfiguration/saveMMRConfiguration', params, { withCredentials: false });
  }

  getMMRConfigurations(params){
    return this.httpClient.post(environment.authApiBase + '/MMRConfiguration/getMMRConfigurations', params, { withCredentials: false });
  }

  getMMRConfigurationInstance(params){
    return this.httpClient.post(environment.authApiBase + '/MMRConfiguration/getMMRConfigurationInstance', params, { withCredentials: false });
  }

  deleteMMRConfiguration(params){
    return this.httpClient.post(environment.authApiBase + '/MMRConfiguration/deleteMMRConfiguration', params, { withCredentials: false });
  }

  updateMMRConfiguration(params){
    return this.httpClient.post(environment.authApiBase + '/MMRConfiguration/updateMMRConfiguration', params, { withCredentials: false });
  }

  getMMRConfigurationsBySite(params){
    return this.httpClient.post(environment.authApiBase + '/MMRConfiguration/getMMRConfigurationsBySite', params, { withCredentials: false });
  }

  getMMRReadings(reqData){
    return this.httpClient.post(environment.authApiBase + '/MMRConfiguration/getMMRReadings', reqData, { withCredentials: false });
  }
}