import { Injectable } from "@angular/core";

@Injectable({ providedIn: 'root' })

export class CommonFunctionService {

  clearLocalStorage() {
    localStorage.removeItem('currentUser');
    // sessionStorage.removeItem('spUuid');
    sessionStorage.removeItem('clientUuid');
  }
}