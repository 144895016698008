// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebase: {
    apiKey: "YOUR_APIKEY",
    authDomain: "YOUR_AUTH_DOMAIN",
    databaseURL: "YOUR_DATABASE_URL",
    projectId: "YOUR_PROJECT_ID",
    storageBucket: "universal-angular-85d7e.appspot.com",
    messagingSenderId: "YOUR_MESSAGE_SENDER_ID",
    appId: "YOUR_APPID"
  },
  // authApiBase: 'http://localhost:9090',
  // frontApiBase: 'http://localhost:4200',
  // authApiBase: 'https://simba-dev-api.corecotechnologies.com',//staging
  // frontApiBase: 'https://simba-dev.corecotechnologies.com', //staging
  authApiBase: 'https://simba-api.corecotechnologies.com', //production
  frontApiBase: 'https://simba.corecotechnologies.com', //production
  reCaptchaSiteKey:"6LfFAeAgAAAAAAWpwO3d-DGxAgn8eF_fx9SI3aLr", //production
  // reCaptchaSiteKey:"6LcjSLUgAAAAABufwYo8jrlZl4ZHbM7nhATPrEih", //staging
  // reCaptchaSiteKey:"6LfDGJkgAAAAAKGTjUyz6oucZ_VY3NH3Rdjc1D7E", //local
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
