import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })


export class TDMService {
    body: any;

    constructor(public httpClient: HttpClient) { }

    /**********************************************Checklist****************************** */


    //to get listing of shift/days of checklist data table
    getChecklistData(assetId, flag) {


        switch (flag) {
            case 1:
                this.body = {
                    assetId: assetId,
                    viewId: "currentMonth",
                    selectedMonthYear: "",
                    withCredentials: false
                }
                break;
            case 2:
                this.body = {
                    assetId: assetId,
                    viewId: "last30Days",
                    selectedMonthYear: "",
                    withCredentials: false
                }
                break;
            case 3:
                this.body = {
                    assetId: assetId,
                    viewId: "",
                    selectedMonthYear: "",
                    withCredentials: false
                }
                break;

        }




        return this.httpClient.post(environment.authApiBase + '/tdm/getChecklistData', this.body);
    }


    //to get checklist Instance data, array of quest/answer on basis of specific checklistInstanceId
    getChecklistInstanceDetails(checklistInstanceId) {
        //body to pass to API
        this.body = {
            checklistInstanceId: checklistInstanceId,
            withCredentials: false
        }
        return this.httpClient.post(environment.authApiBase + '/tdm/getChecklistInstanceDetails', this.body);
    }

    //to submit Supervisor's Comments that will be updated in DB as per specific checklistInstance ID to its specific AssetId
    submitSupervisorComment(checklistInstanceId, comment) {
        //body to pass to API
        this.body = {
            checklistInstanceId: checklistInstanceId,
            supervisorComment: comment,
            withCredentials: false
        }

        return this.httpClient.post(environment.authApiBase + '/tdm/submitChecklistSupervisorComment', this.body);

    }

    /**********************************************LOGSHEET****************************** */

    //to get logsheet Instance data, array of quest/answer on basis of specific logsheetInstanceId
    getLogsheetInstanceDetails(logSheetInstanceId) {
        //body to pass to API
        this.body = {
            logSheetInstanceId: logSheetInstanceId,
            withCredentials: false
        }
        return this.httpClient.post(environment.authApiBase + '/tdm/getLogsheetInstanceDetails', this.body);
    }

    //temp functionality : to get logsheet instance id from multiple ones, which have logsheet data
    getLogsheetInstanceId(assetId) {
        //body to pass to API
        this.body = {
            assetId: assetId,
            withCredentials: false
        }
        return this.httpClient.post(environment.authApiBase + '/tdm/getLogsheetInstanceId', this.body);
    }

    //to submit Supervisor's Comments that will be updated in DB as per specific logsheetInstance ID to its specific AssetId
    submitLogsheetSupervisorComment(logsheetInstanceId, comment) {
        //body to pass to API
        this.body = {
            logsheetInstanceId: logsheetInstanceId,
            supervisorComment: comment,
            withCredentials: false
        }


        return this.httpClient.post(environment.authApiBase + '/tdm/submitLogsheetSupervisorComment', this.body);

    }



    //to get listing of logsheet data
    getLogsheetData(assetId, flag, minDate, maxDate) {


        switch (flag) {
            case 1:
                this.body = {
                    assetId: assetId,
                    viewId: "multiDayList",
                    fromDate: minDate,
                    toDate: maxDate,
                    selectedMonthYear: "",
                    withCredentials: false
                }
                break;
            case 2:
                break;
            case 3:
                break;

        }




        return this.httpClient.post(environment.authApiBase + '/tdm/getlogsheetData', this.body);
    }

    //todo : to move to its proper services
    //to get count of overall data for dashboard for specific spuuid
    toGetOverallCountForSpUuid() {
        this.body = {
            spUuid: JSON.parse(atob(localStorage.getItem('spUserData')))['SpUUID'],
            uRoleId: JSON.parse(atob(localStorage.getItem('spUserData')))['uRoleId'],
            uClients: JSON.parse(atob(localStorage.getItem('spUserData')))['uClients'],
            uSites: JSON.parse(atob(localStorage.getItem('spUserData')))['uSites'],
            uProperties: JSON.parse(atob(localStorage.getItem('spUserData')))['uProperties'],
            UserUUID: JSON.parse(atob(localStorage.getItem('spUserData')))['UserUUID'],
            withCredentials: false
        }
        return this.httpClient.post(environment.authApiBase + '/tdm/home', this.body);

    }


    logSheetReport(params){
      this.body =  params;
        return this.httpClient.post(environment.authApiBase + '/tdm/LogSheetReport',this.body, {withCredentials: false});
    }

    deleteLogsheetDetails(params){
        return this.httpClient.post(environment.authApiBase + '/tdm/deleteLogsheetDetails', params, {withCredentials: false});
    }

    updateLogsheetAnswers(params){
      return this.httpClient.post(environment.authApiBase + '/tdm/updateLogsheetAnswers', params, {withCredentials: false});
    }

    homeDashboard(fromDate, toDate, siteId) {
      this.body = {
          spUuid: JSON.parse(atob(localStorage.getItem('spUserData')))['SpUUID'],
          UserUUID: JSON.parse(atob(localStorage.getItem('spUserData')))['UserUUID'],
          uRoleId: JSON.parse(atob(localStorage.getItem('spUserData')))['uRoleId'],
          uClients: JSON.parse(atob(localStorage.getItem('spUserData')))['uClients'],
          uSites: JSON.parse(atob(localStorage.getItem('spUserData')))['uSites'],
          uProperties: JSON.parse(atob(localStorage.getItem('spUserData')))['uProperties'],
          fromDate: fromDate,
          toDate:toDate,
          siteId: siteId,
          withCredentials: false
      }
      return this.httpClient.post(environment.authApiBase + '/tdm/homeDashboard', this.body);
    }

    tdmDashboard(fromDate, toDate, siteId){
      this.body = {
        spUuid: JSON.parse(atob(localStorage.getItem('spUserData')))['SpUUID'],
        uClients: JSON.parse(atob(localStorage.getItem('spUserData')))['uClients'],
        uSites: JSON.parse(atob(localStorage.getItem('spUserData')))['uSites'],
        fromDate:fromDate,
        toDate: toDate,
        siteId: siteId,
        withCredentials: false
      }
      return this.httpClient.post(environment.authApiBase + '/tdm/tdmDashboard', this.body);
    }

    softServicesDashboard(fromDate, toDate){
      this.body = {
        spUuid: JSON.parse(atob(localStorage.getItem('spUserData')))['SpUUID'],
        uRoleId: JSON.parse(atob(localStorage.getItem('spUserData')))['uRoleId'],
        uClients: JSON.parse(atob(localStorage.getItem('spUserData')))['uClients'],
        uProperties: JSON.parse(atob(localStorage.getItem('spUserData')))['uProperties'],
        fromDate:fromDate,
        toDate: toDate,
        withCredentials: false
      }
      return this.httpClient.post(environment.authApiBase + '/tdm/homeSoftServicesCount', this.body);
    }

    homeAttendanceCount(fromDate, toDate, siteId){
      this.body = {
        spUuid: JSON.parse(atob(localStorage.getItem('spUserData')))['SpUUID'],
        uRoleId: JSON.parse(atob(localStorage.getItem('spUserData')))['uRoleId'],
        uClients: JSON.parse(atob(localStorage.getItem('spUserData')))['uClients'],
        uSites: JSON.parse(atob(localStorage.getItem('spUserData')))['uSites'],
        uProperties: JSON.parse(atob(localStorage.getItem('spUserData')))['uProperties'],
        fromDate:fromDate,
        toDate: toDate,
        siteId: siteId,
        withCredentials: false
      }
      return this.httpClient.post(environment.authApiBase + '/tdm/homeAttendanceCount', this.body); 
    }

    workOrderDashboard(fromDate, toDate, siteId){
      this.body = {
        spUuid: JSON.parse(atob(localStorage.getItem('spUserData')))['SpUUID'],
        uClients: JSON.parse(atob(localStorage.getItem('spUserData')))['uClients'],
        uSites: JSON.parse(atob(localStorage.getItem('spUserData')))['uSites'],
        fromDate:fromDate,
        toDate: toDate,
        siteId: siteId,
        withCredentials: false
      }
      return this.httpClient.post(environment.authApiBase + '/tdm/workOrderDashboard', this.body); 
    }
    
}