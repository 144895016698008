import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LoaderComponent } from "./components/loader/loader.component";
import { ListingLoaderComponent } from "./components/listingLoader/listing-loader.component";
import { HeaderComponent } from "./components/header/header.component";
import { FooterComponent } from "./components/footer/footer.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { ContentLayoutComponent } from "./components/layout/content-layout/content-layout.component";
import { FeatherIconsComponent } from "./components/feather-icons/feather-icons.component";
import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb.component";
import { RightSidebarComponent } from "./components/right-sidebar/right-sidebar.component";
import { BookmarkComponent } from "./components/bookmark/bookmark.component";
import { TranslateModule } from "@ngx-translate/core";
import { CustomizerComponent } from "./components/customizer/customizer.component";
import { DragulaModule } from "ng2-dragula";
import { NgbModule, NgbDateParserFormatter } from "@ng-bootstrap/ng-bootstrap";
import { GalleryModule } from "@ks89/angular-modal-gallery";
import "hammerjs";
import "mousetrap";

// services
import { NavService } from "./services/nav.service";
import { ChatService } from "./services/chat.service";
import { CustomizerService } from "./services/customizer.service";
// Directives
import { ToggleFullscreenDirective } from "./directives/fullscreen.directive";
import { CapitalizeFirstPipe } from "./pipes/capitalize-first.pipe";
import { ConfirmDialog } from "./dialog/confirm-dialog.component";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { CustomPipe } from "../tdmDashboard/checklistDashboard/custom-pipe";
import { NgbDateCustomParserFormatter } from "../utilService";
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { AssetComponent } from "../assetManagement/asset/asset.component";
import { SearchBoxComponent } from "./components/search-box/search-box.component";
import { ButtonDetailsComponent } from './components/button-details/button-details.component';
import { QRGenerationComponent } from "./components/qr-generation/qr-generation.component";
import { SchedulerComponent } from "./components/schedular/schedular.component";
import { ImagePipe } from "../assetManagement/image-pipe";
import { NgxListingComponent } from "./components/ngx-listing/ngx-listing.component";
import { DateSchedularComponent } from './components/date-schedular/date-schedular.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { DateFilterComponent } from './components/date-filter/date-filter.component';
import { DynamicAttributeSetComponent } from './components/dynamic-attribute-set/dynamic-attribute-set.component';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [
    ImagePipe,
    LoaderComponent,
    ListingLoaderComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    BookmarkComponent,
    RightSidebarComponent,
    ContentLayoutComponent,
    FeatherIconsComponent,
    ToggleFullscreenDirective,
    BreadcrumbComponent,
    CustomizerComponent,
    CapitalizeFirstPipe,
    ConfirmDialog,
    CustomPipe,
    AssetComponent,
    SearchBoxComponent,
    ButtonDetailsComponent,
    QRGenerationComponent,
    NgxListingComponent,
    SchedulerComponent,
    DateSchedularComponent,
    DateFilterComponent,
    DynamicAttributeSetComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    DragulaModule.forRoot(),
    NgbModule,
    GalleryModule.forRoot(),
    NgxDatatableModule,
    Ng2SmartTableModule,
    NgSelectModule,
    NgMultiSelectDropDownModule.forRoot() 
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    LoaderComponent,
    ListingLoaderComponent,
    FeatherIconsComponent,
    TranslateModule,
    CapitalizeFirstPipe,
    NgxDatatableModule,
    CustomPipe,
    Ng2SmartTableModule,
    SearchBoxComponent,
    ButtonDetailsComponent,
    QRGenerationComponent,
    ImagePipe,
    NgxListingComponent,
    SchedulerComponent,
    DateSchedularComponent,
    DateFilterComponent,
    DynamicAttributeSetComponent,
    FooterComponent
  ],
  providers: [NavService, ChatService, CustomizerService, { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }],
  entryComponents: [ConfirmDialog]
})
export class SharedModule { }
