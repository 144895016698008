import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })

export class WorkOrderService {
  constructor(public httpClient: HttpClient) { }

  getWorkOrders(params){
    return this.httpClient.post(environment.authApiBase + '/workOrder/getWorkOrders', params, { withCredentials: false });
  }

  getWorkOrderDetails(params){
    return this.httpClient.post(environment.authApiBase + '/workOrder/getWorkOrderDetails', params, { withCredentials: false });
  }

  getProducts(){
    return this.httpClient.post(environment.authApiBase + '/workOrder/getProducts', { withCredentials: false });
  }

  getModelsByProduct(params){
    return this.httpClient.post(environment.authApiBase + '/workOrder/getModelsByProduct', params, { withCredentials: false });
  }

  getComplaintTypes(){
    return this.httpClient.post(environment.authApiBase + '/workOrder/getComplaintTypes', { withCredentials: false });
  }

  updateWorkOrderDetails(params){
    return this.httpClient.post(environment.authApiBase + '/workOrder/updateWorkOrderDetails', params, { withCredentials: false });
  }

   addWorkOrder(params){
    return this.httpClient.post(environment.authApiBase + '/workOrder/addWorkOrder', params, { withCredentials: false });
  }

  getEngineerManagerDetails(AssEngineerId)
  {
    return this.httpClient.post(environment.authApiBase + '/user/getEngineerManagerDetails', {'AssEngineerId':AssEngineerId}, { withCredentials: false });
  }

  getSparesUsed(params){
    return this.httpClient.post(environment.authApiBase + '/workOrder/getSparesUsed', params, { withCredentials: false });
  }

  addSparesUsed(params){
    return this.httpClient.post(environment.authApiBase + '/workOrder/addSparesUsed', params, { withCredentials: false });
  }

  updateSparesUsed(params){
    return this.httpClient.post(environment.authApiBase + '/workOrder/updateSparesUsed', params, { withCredentials: false });
  }

  deleteSparesUsed(params){
    return this.httpClient.post(environment.authApiBase + '/workOrder/deleteSparesUsed', params, { withCredentials: false });
  }

  getFaultTypes(params){
    return this.httpClient.post(environment.authApiBase + '/workOrder/getFaultTypes', params, { withCredentials: false });
  }

  getFaultCountModelWise(params){
    return this.httpClient.post(environment.authApiBase + '/workOrder/getFaultCountModelWise', params, { withCredentials: false });
  }
}
