import { Injectable, HostListener } from "@angular/core";
import { BehaviorSubject, Observable, Subscriber } from "rxjs";

// Menu
export interface Menu {
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
  id?: string;
}

@Injectable({
  providedIn: "root"
})
export class NavService {
  public screenWidth: any;
  public collapseSidebar: boolean = false;
  public sidebar: any[] = []
  public childrenRoutes: any[];
  constructor() {
    this.onResize();
    if (this.screenWidth < 991) {
      this.collapseSidebar = true;
    }
  }

  // Windows width
  @HostListener("window:resize", ["$event"])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
  }


  //this method will be called from sidebar to get menu as per roles
  menuItemsRoleBased() {
    //making sure that sidebar array is empty before adding items or sidebar hits to this method
    this.sidebar = [];
    let role_id = JSON.parse(atob(localStorage.getItem('spUserData')))['uRoleId']


    //Data Count dashboard
    if (role_id == 0 || role_id == 1 || role_id == 2 || role_id == 3 || role_id == 4 || role_id == 5 || role_id == 6 || role_id == 7 || role_id == 8 || role_id == 9 || role_id == 10) {
      this.sidebar.push({
        path: "/dashboard",
        title: "Home",
        icon: "home",
        type: "link",
        id: "homeMenu"
      })
    }

     /***************************************Old dashboard **********************************************/
     if (role_id == 0 || role_id == 1 || role_id == 2 || role_id == 3 || role_id == 4 || role_id == 5 || role_id == 6 || role_id == 7 || role_id == 8 || role_id == 9 || role_id == 10) {
      this.sidebar.push({
        path: "/dashboard/baseDashboard",
        title: "My Stats",
        icon: "home",
        type: "link",
        id: "oldHomeMenu"
      })
    }
    /**********************************************************************************************************************************/

    //Management : Manage
    if (role_id == 0 || role_id == 1 || role_id == 2 || role_id == 3 || role_id == 4 || role_id == 5 || role_id == 7 || role_id == 9 ) {

      this.childrenRoutes = []
      if (role_id == 0 || role_id == 1 || role_id == 2 || role_id == 3 || role_id == 4 || role_id == 5 || role_id == 7 || role_id == 9
      ) {
        this.childrenRoutes = [
          { path: "/on-boarding/all-sites", title: "Sites", type: "link", id: "sitesSubmenu" },
          { path: "/on-boarding/all-clients", title: "Clients", type: "link", id:"clientsSubmenu" },
          { path: '/soft-services/retailsProperties', title: "Properties", type: "link", id: "propertiesSubmenu" }]
      }

      // if (role_id == 0 || role_id == 2 || role_id == 3 || role_id == 5 || role_id == 7 || role_id == 9) {
      //   this.childrenRoutes.push({ path: "", title: "Imports", type: "link", id: "importSubmenu" })
      // }
      // if (role_id == 8 || role_id == 10) {
      //   this.childrenRoutes = [
      //     { path: "/on-boarding/all-clients", title: "Clients", type: "link", id: "clientsSubmenu" }]
      // }
      this.sidebar.push({
        title: "Manage",
        icon: "user-plus",
        type: "sub",
        badgeType: "primary",
        active: false,
        children: this.childrenRoutes,
        id: "manageMenu"
      });

    }
   
    /**********************************************************************************************************************************/
    //TDM
    if (role_id == 0 || role_id == 1 || role_id == 2 || role_id == 3 || role_id == 4 || role_id == 5 || role_id == 7 || role_id == 9) {
      this.childrenRoutes = [];

      if (role_id == 0 || role_id == 1 || role_id == 2 || role_id == 3 || role_id == 4 || role_id == 5 || role_id == 7 || role_id == 9) {
        this.childrenRoutes.push({
          path: "/asset-management/asset", title: "Assets", type: "link", id: "assetsSubmenu"
        })
      }
      if (role_id == 0 || role_id == 1 || role_id == 2 || role_id == 3 || role_id == 5 || role_id == 7 || role_id == 9) {
        this.childrenRoutes.push({ path: "/TDM/standardChecklist", title: "Checklists", type: "link", id: "checklistsSubmenu" },
          { path: "/TDM/standardLogsheet", title: "Logsheets", type: "link", id: "logsheetsSubmenu" },
          { path: "/TDM/MMRListing", title: "MMR Configuration", type: "link", id: "MMRConfigurationListingSubmenu" })
        }

     

      // if (role_id == 0 || role_id == 2 || role_id == 3 || role_id == 4 || role_id == 5 || role_id == 7 || role_id == 9) {
      //   this.childrenRoutes.push({
      //     path: "", title: "PPM", type: "link", id: "ppmSubmenu"
      //   })
      // }

      this.sidebar.push({
        title: "TDM",
        icon: "activity",
        type: "sub",
        badgeType: "primary",
        active: false,
        children: this.childrenRoutes,
        id: "tdmMenu" 
      });
    }
    /**********************************************************************************************************************************/
    //soft services
    // if (role_id == 0 || role_id == 4 || role_id == 5 || role_id == 7 || role_id == 8 || role_id == 9 || role_id == 10) {
    //   this.childrenRoutes = [];
    //   if (role_id == 0 || role_id == 4 || role_id == 5 || role_id == 7 || role_id == 8 || role_id == 9 || role_id == 10) {
    //     this.childrenRoutes.push(
    //       { path: '/soft-services/retailsProperties', title: "Properties", type: "link", id: "propertiesSubmenu" })
    //   }
      // if (role_id == 0 || role_id == 4 || role_id == 5 || role_id == 7 || role_id == 9 || role_id == 10) {
      //   this.childrenRoutes.push(
      //     { path: '', title: "Schedules", type: "link", id: "schedulesSubmenu" },
      //     { path: '/soft-services/retailEstimation', title: "Costing", type: "link", id: "costingSubmenu" })
      // }
      // if (role_id == 0 || role_id == 5 || role_id == 7 || role_id == 9 || role_id == 10) {
      //   this.childrenRoutes.push(
      //     { path: '/soft-services/retailSettings', title: "Retails Settings", type: "link", id: "retailsSettingsSubmenu" })
      // }



    //   this.sidebar.push({
    //     title: "Soft Service",
    //     icon: "check-square",
    //     type: "sub",
    //     badgeType: "primary",
    //     active: false,
    //     children: this.childrenRoutes,
    //     id: "softServicesMenu"
    //   });
    // }

    // attendance module
    if (role_id == 0 || role_id == 1 || role_id == 2 || role_id == 3 || role_id == 5 || role_id == 7 || role_id == 8 || role_id == 9 || role_id == 10) {
      this.childrenRoutes = [];
      this.childrenRoutes = [
        { path: "/attendance/attendance-dashboard", title: "Dashboard", type: "link", id: "attendanceDashboardSubmenu" },
        { path: "/attendance/attendance-reports", title: "Report", type: "link", id: "attendanceReportSubmenu" }
      ];
      this.sidebar.push({
        title: "Attendance",
        icon: "tag",
        active: false,
        children: this.childrenRoutes,
        type: "sub",
        badgeType: "primary",
        id: "attendanceMenu"
      });
    }


    // Ticket module
    if (role_id == 0 || role_id == 1 || role_id == 2 || role_id == 3 || role_id == 4 || role_id == 5 || role_id == 7 || role_id == 8 || role_id == 9 || role_id == 10) {
      this.childrenRoutes = [];
      this.childrenRoutes = [
        { path: "/ticket/dashboard", title: "Dashboard", type: "link", id: "ticketDashboardSubmenu" },
        { path: "/ticket/all", title: "listing", type: "link", id: "ticketListingSubmenu" }
      ];
      this.sidebar.push({
        title: "Tickets",
        icon: "tag",
        type: "sub",
        badgeType: "primary",
        active: false,
        children: this.childrenRoutes,
        id: "ticketsMenu"
      });
    }



    //Finance
    // if (role_id == 0 || role_id == 6 || role_id == 7 || role_id == 9) {
    //   this.childrenRoutes = []

    //   this.childrenRoutes = [
    //     { path: "/creditManagment/report", title: "Outstandings", type: "link", id: "outstandingsSubmenu" },
    //     { path: "/creditManagment/invoices", title: "Invoices", type: "link", id: "invoicesSubmenu" },
    //     { path: "/creditManagment/clientOnBoardingListing", title: "Reminders", type: "link", id: "remindersSubmenu" }
    //   ]
    //   this.sidebar.push({
    //     title: "Finance",
    //     icon: "dollar-sign",
    //     type: "sub",
    //     active: false,
    //     children: this.childrenRoutes,
    //     id: "financeMenu"
    //   });
    // }
    /**********************************************************************************************************************************/

    //Training Material
    if (role_id == 0 || role_id == 1 || role_id == 2 || role_id == 3 || role_id == 5 || role_id == 7 || role_id == 8 || role_id == 9 || role_id == 10) {
      this.sidebar.push({
        path: "/training-material/training-dashboard",
        title: "Training",
        icon: "monitor",
        active: false,
        type: "link",
        id: "trainingMenu"
      });
    }
/***********************************************************************************************************************************/
      //Work Order
      if (role_id == 0 || role_id == 1 ||  role_id == 5 || role_id == 7 || role_id == 9) {
      //   this.childrenRoutes = []

      //   this.childrenRoutes = [
      //     { path: "/work-order/listing", title: "Listing", type: "link", id: "workOrderListingSubmenu" },
      //     // { path: "/work-order/details", title: "Details", type: "link", id: "workOrderDetailsSubMenu" }
      //   ]
        this.sidebar.push({
          path: "/work-order/listing",
          title: "Work Order",
          icon: "tag",
          type:"link",
          badgeType: "primary",
          active: false,
      //     children: this.childrenRoutes,
          id: "workOrderMenu"
        });
      }
        
    /***********************************************************************************************************************************/

    //Reports MMR
    if (role_id == 1 || role_id == 2 || role_id == 3 || role_id == 4 || role_id == 5 || role_id == 7 || role_id == 9 || role_id == 0) {
      // this.childrenRoutes = []
      this.childrenRoutes = [
        // { path: "/TDM/LogsheetReport", title: "MMR", type: "link", id: "logsheetReportSubmenu" },
        { path: "/work-order/dashboard", title:"Failure Report", type: "link", id: "failureReportSubmenu" },
        { path: "/TDM/MMRCharts", title:"MMR", type: "link", id: "MMRSubmenu" },

      ]
      this.sidebar.push({
        title: "Reports",
        icon: "bar-chart",
        type: "sub",
        active: false,
        children: this.childrenRoutes,
        id: "reportsMenu"
      });
    }


    //Settings
    if (role_id == 7 || role_id == 9 || role_id == 0) {
      this.childrenRoutes = []
      this.childrenRoutes = [
        { path: "/on-boarding/all-users", title: "Users", type: "link", id: "allUsersSubmenu" },
        { path: "/sp-simba-admin/sp-master-records-list", title: "Masters", type: "link", id: "spMastersSubmenu" },
        { path: "/dynamic-attributes/listing", title: "Dynamic Attributes", type: "link", id: "dynamicAttributesSubmenu" },
        // { path: "/scheduler/schedule-details", title: "tempSchedule", type: "link", id: "scheduleSubmenu" },
      ]
      this.sidebar.push({
        title: "Settings",
        icon: "settings",
        type: "sub",
        active: false,
        children: this.childrenRoutes,
        id: "settingsMenu"
      });
    }

    //IOT
    // if (role_id == 0 || role_id == 1 || role_id == 2 || role_id == 3 || role_id == 4 || role_id == 5 || role_id == 7 || role_id == 9 || role_id == 10) {
    //   this.sidebar.push({
    //     path: "/simba-iot",
    //     title: "IoT",
    //     icon: "cloud-lightning",
    //     type: "sub",
    //     badgeType: "primary",
    //     active: false,
    //     id: "simba-iotMenu",
    //     children: [
    //       {
    //         path: "/simba-iot/iot-dashboard",
    //         title: "Energy",
    //         type: "link",
    //         id: "energySubmenu"
    //       },
    //       {
    //         path: "/simba-iot/iot-assets-tracking",
    //         title: "Fixed Assets",
    //         type: "link",
    //         id: "fixedAssetsSubmenu"
    //       },
    //       {
    //         path: "/simba-iot/mobile-assets-tracking",
    //         title: "Moving Assets",
    //         type: "link",
    //         id: "movingAssetsSubmenu"
    //       },
    //       {
    //         path: "/simba-iot/mobile-assets-tracking2",
    //         title: "Moving Assets2",
    //         type: "link",
    //         id: "movingAssets2Submenu"
    //       },
    //     ]
    //   })
    // }

    /**********************************************************************************************************************************/
    //simba-admin
    if (role_id == 0) {
      this.sidebar.push({
        title: "Simba",
        icon: "command",
        type: "sub",
        badgeType: "primary",
        active: false,
        id: "simbaMenu",
        children: [
          { path: "/simba-admin/master-records-list", title: "Masters", type: "link", id:"mastersSubmenu" },
          {
            path: "/simba-admin/service-provider",
            title: "Service-Providers",
            type: "link"
          },
          { path: "/simba-admin/deployment", title: "Deployment", type: "link", id: "deploymentSubmenu" },
          { path: "/simba-admin/demo-data-setup", title: "Demo Setup", type: "link", id: "demoSetupSubmenu" }
        ]
      })

    }



    return this.sidebar;
  }

  //not in use - start
  MENUITEMS: Menu[] = [
    {
      path: "/dashboard",
      title: "Home",
      icon: "home",
      type: "link"
    },
    {
      path: "/simba-iot",
      title: "Simba IOT",
      icon: "cloud-lightning",
      type: "link"
    },
    {
      title: "Technical Data",
      icon: "activity",
      type: "sub",
      badgeType: "primary",
      active: false,
      children: [
        { path: "/asset-management/asset", title: "Assets", type: "link" }
      ]
    },
    {
      title: "Credit Management",
      icon: "credit-card",
      type: "sub",
      active: false,
      children: [
        {
          path: "/creditManagment/report",
          title: "Outstandings",
          type: "link"
        },
        { path: "/creditManagment/invoices", title: "Invoices", type: "link" },
        {
          path: "/creditManagment/clientOnBoardingListing",
          title: "Reminders",
          type: "link"
        }
      ]
    },
    {
      title: "On-boarding",
      icon: "user-plus",
      type: "sub",
      badgeType: "primary",
      active: false,
      children: [
        { path: "/on-boarding/users", title: "Users", type: "link" },
        { path: "/on-boarding/sitesListing", title: "Sites", type: "link" },
        { path: "/on-boarding/all-clients", title: "Clients", type: "link" },
        { path: "/import/file", title: "Bulk Import", type: "link" }
      ]
    },

    {
      title: "Soft Services",
      icon: "clock",
      type: "sub",
      badgeType: "primary",
      active: false,
      children: [
        {
          path: '/soft-services/retailSettings',
          title: "Retails Settings",
          type: "link"
        },
        {
          path: '/soft-services/retailEstimation',
          title: "Retails Estimation",
          type: "link"
        },
      ]
    },
    {
      title: "Monthly Reports",
      icon: "file-text",
      type: "sub",
      badgeType: "primary",
      active: false,
      children: []
    },
    {
      title: "Simba Admin",
      icon: "user-plus",
      type: "sub",
      badgeType: "primary",
      active: false,
      children: [
        { path: "/simba-admin/service-provider", title: "Service-Provider", type: "link" },
        { path: "/simba-admin/deployment", title: "Deployment", type: "link" },
        { path: "/simba-admin/masterRecords", title: "Master Record", type: "link" },
        { path: "/simba-admin/demo-data-setup", title: "Demo Setup", type: "link" }
      ]
    }

  ];
  //not in use - end
  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
}
