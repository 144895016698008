//declaration for Asset Details instance
export class assetDetails {
    constructor(
        public loggedUser: loggedUserDetails,
        public AssetCategory: string = "",
        public AssetType: string = "",
        public AssetName: string = "",
        public AssetCode: string = "",
        public ClientUUID: string = "",
        public AssetBuilding: string = "",
        public AssetLocation: string = "",
        public categoryName: string = "",
        public AssetSerialNumber: string = "",
        public AssetCommissionDate: string = "",
        public AssetWarrantyDateFrom: string = "",
        public AssetRelatedComments: string = "",
        public typeName: string = "",
        public AssetModelNumber: string = "",
        public AssetSpecification: string = "",
        public AssetHandoverDate: string = "",
        public AssetWarrantyDateTo: string = "",
        public AssetMake: string = "",
        public SiteName: string = "",
        public AssetAMCEndDate: string = "",
        public AssetAMCStartDate: string = "",
        public ChecklistFrequency: number = 0,
        public LogsheetFrequency: number = 0,
        public SiteUUID: number = 0,
        public AssetCurrentChecklist: string = "",
        public AssetCurrentLogsheet: string = "",
        public AssetCurrentChecklistName: string = "",
        public AssetCurrentLogsheetName: string = "",
        public AssetCurrentPPMChecklist: string = "",
        public AssetCurrentPPMChecklistName: string = "",
        public statusName: string = "",
        public AssetStatus: string = "1",
        public AssetPhotoURL = "",
        public assetPhoto = [],
        public AssetPhotosData = [],
        public ParentAsset = "",
        public ChildrenAsset = ""
    ) { }
}

//declaration for checklist Data instance
export class checklistDetails {
    constructor(
        public UserName: string = "",
        public DataCollectionLatitude: string = "",
        public DataCollectionLongitude: string = "",
        public date: string = "",
        public checklistDataArray: string = "",
        public ChecklistCommentsTechnician: string = "",
        public ChecklistStatus: number = 0,
        public ChecklistCommentsSupervisor: string = "",
        public ChecklistCommentsSupervisorDateTime: string = ""

    ) { }
}

//declaration for logsheet Data instance
export class logsheetDetails {
    constructor(
        public UserName: string = "",
        public DataCollectionLatitude: string = "",
        public DataCollectionLongitude: string = "",
        public date: string = "",
        public logsheetDataArray: string = "",
        public LogsheetCommentsTechnician: string = "",
        public LogsheetStatus: number = 0,
        public LogsheetCommentsSupervisor: string = "",
        public LogsheetCommentsSupervisorDateTime: string = ""
    ) { }
}


//declaration for homeDashboard
export class homeDashboard {
    constructor(
        public users: string = "",
        public invoices: string = "",
        public sites: string = "",
        public clients: string = "",
        public assets: string = "",
        public standardChecklists: string = "",
        public standardLogsheet: string = "",
        public tickets: string = "",
        public properties: string = "",
        public units: string = "",
        public buildings: string = ""
    ) { }
}


//declaration for invoice details
export class invoiceDetails {
    constructor(
        public ClientName: string = "",
        public BillingSeries: string = "",
        public InvoiceNumber: string = "",
        public ClientExtReferenceID1: string = "",
        public ClientExtReferenceID2: string = "",
        public InvoiceDate: string = "",
        public PaymentDueDate: string = "",
        public InvoiceAmount: number = 0,
        public InvoicePaidAmount: number = 0,
        public ReferencePONumber: string = "",
        public InvoiceStatus: string = "",
        public outstandingAmount: number = 0,
        public TDSDeducted: number = 0
    ) { }
}




//declaration for client reminder details
export class clientReminderDetails {
    constructor(
        public loggedUser: loggedUserDetails,
        public ClientName: string = "",
        public ClientUUID: string = "",
        public ClientInvoicePaymentTermsDays: string = "",
        public SpUUID: number = 0,
        public SiteName: string = "",
        public L1FollowupEmail = [],
        public L1FollowupPhone = [],
        public L1FollowupDays: number = 0,
        public L1FollowupPolicy: number = 0,
        public L1FollowupIsActive: number = 0,
        // public L1Active: boolean = true,
        public L2FollowupEmail = [],
        public L2FollowupPhone = [],
        public L2FollowupDays: number = 0,
        public L2FollowupPolicy: number = 0,
        public L2FollowupIsActive: number = 0,
        // public L2Active: boolean = true,
        public L3FollowupEmail = [],
        public L3FollowupPhone = [],
        public L3FollowupDays: number = 0,
        public L3FollowupPolicy: number = 0,
        public L3FollowupIsActive: number = 0,
        // public L3Active: boolean = true,
    ) { }
}



//client details
export class clientDetails {
    constructor(
        public ClientName: string = "",
        public BusinessSegmentType: string = "",
        public ClientAddress: string = "",
        public ClientBusinessSegment: number = 1,
        public ClientCity: string = "",
        public ClientContactPersonDesignation1: string = "",
        public ClientContactPersonDesignation2: string = "",
        public ClientContactPersonEmailId1: string = "",
        public ClientContactPersonEmailId2: string = "",
        public ClientContactPersonName1: string = "",
        public ClientContactPersonName2: string = "",
        public ClientContactPersonPhoneNumber1: string = "",
        public ClientContactPersonPhoneNumber2: string = "",
        public ClientDisplayName: string = "",
        public ClientFacilityArea: string = "",
        public ClientIntegrationRef: string = "",
        public ClientOnboardMonthYear: number = 0,
        public ClientPinCode: number = 0,
        public ClientPortfolioType: number = 1,
        public ClientState: string = "",
        public ClientStatus: number = 1,
        public ClientStatusName: string = "",
        public ClientUUID: string = "",
        public CreatedBy: string = JSON.parse(atob(localStorage.getItem('spUserData')))['uFullName'],
        public LastUpdatedBy: string = "",
        public PortfolioType: string = "",
        public SpUUID: number = JSON.parse(atob(localStorage.getItem('spUserData')))['SpUUID']
    ) { }
}


//site details
export class siteDetails {
    constructor(
        public SiteDisplayName: string = "",
        public SitePinCode: number = 0,
        public SiteClientContactDesignation: string = "",
        public SiteClientContactEmail: string = "",
        public SiteAddress: string = "",
        public SiteCity: string = "",
        public ClientUUID: number = 0,
        public SiteClientContactName: string = "",
        public SiteClientContactPhone: string = "",
        public SiteName: string = "",
        public SiteState: string = "",
        public SiteStatusId: number = 1,
        public SiteUUID: number = 1,
        public CreatedBy: string = JSON.parse(atob(localStorage.getItem('spUserData'))).uFullName,
        public SpUUID: number = JSON.parse(atob(localStorage.getItem('spUserData'))).SpUUID,
        public LastUpdatedBy: string = JSON.parse(atob(localStorage.getItem('spUserData'))).uFullName

    ) { }
}

//user details
export class userDetails {
    constructor(
        public loggedUser: loggedUserDetails,
        public UserPhoneNumber: string = "",
        public UserEmail: string = "",
        public IsActive: string = "",
        public UserFirstName: string = "",
        public UserMiddleName: string = "",
        public UserLastName: string = "",
        public UserRole: string = "",
        public UserSupervisor: string = "",
        public CreatedBy: string = "",
        public CreatedOnUtc: string = "",
        public UserClients: string = "",
        public UserSites: string = "",
        public UserProperties: string = "",
        public selectedClientSiteData = [],
        public selectedClientPropertyData = [],
        public searchFilterSiteData = [],
        public searchFilterPropertyData = [],
        public allClientsData = [],
        public dropDownUserClients: any[] = [],
        public dropDownUserSites: any[] = [],
        public dropDownUserPropertiesBeforeAllProperties: any[] = [],
        public dropDownUserProperties: any[] = [],
        public LastWorkingDate: any=[
            null, []
          ],
        public StartDate: string = ""
    ) {

    }
}



//common class//can be used by any class
export class loggedUserDetails {
    constructor(
        public CreatedBy: string = JSON.parse(atob(localStorage.getItem('spUserData'))).uFullName,
        public SpUUID: number = JSON.parse(atob(localStorage.getItem('spUserData'))).SpUUID,
        public LastUpdatedBy: string = JSON.parse(atob(localStorage.getItem('spUserData'))).uFullName) {
    }
}

//softService
export class softServiceDashboard {
    constructor(
        public selectedMonth: string = new Date().getFullYear().toString() + '-' + (new Date().getMonth() + 1),
        public selectedBuilding: any = [],
        public Scheduled: boolean = true,
        public serviceddAsScheduled: boolean = true,
        public rejected: boolean = true,
        public approved: boolean = true,
        public missed: boolean = true,
        public reScheduled: boolean = true,
        public addOnService: boolean = true,
        public dateRange: boolean = true
    ) {
    }
}



//property
export class propertyDetails {
    constructor(
        public ClusterName: string = "",
        public ClusterCity: string = "",
        public PropertyName: string = "",
        public PropertyAddress: string = "",
        public PropertyCity: string = "",
        public PropertyContactPerson: string = "",
        public PropertyContactEmail: string = "",
        public PropertySupervisor: string = ""
    ) {
    }
}


//retail checklist instance (soft service details)
export class softServiceDetails {
    constructor(
        public BuildingName: string = "",
        public ChecklistSupervisorComments: string = "",
        public ChecklistData: any[] = [],
        public DataCollectionDatetime: string = "",
        public DataCollectionLatitude: string = "",
        public DataCollectionLongitude: string = "",
        public SupervisorId: number = 0,
        public SupervisorName: string = "",
        public UnitName: string = "",
        public UnitUUID: number = 0,
        public PropertyName: number = 0,
        public ChecklistApprovalSupervisorStatus: number = 2,
        public UserName: string = "",
        public ChecklistLastSupervisorActionTime: string = "",
        public Id: number = 0
    ) {
    }
}
//assets contact details
export class assetContactDetails {
    constructor(
        public loggedUser: loggedUserDetails,
        public AssetContactName: string = "",
        public AssetContactCompanyName: string = "",
        public AssetContactPhone: string = "",
        public AssetContactAddress: string = "",
        public AssetContactEmail: string = "",
        public AssetContactType: string = "",
        public AssetContactLevel: string = "",
        public AssetUUID: number = 0,
        public AssetContactId: number = 0,
    ) { }
}

export class serviceProviderDetails {
    constructor(
        public loggedUser: loggedUserDetails,
        public SpUUID: number = 0,
        public SpName: string = "",
        public SpStatus: number = 1,
        public SpDisplayName: string = "",
        public SpAppName: string = "",
        public SpLogoURL: string = "",
        public SpContactPersonFirstName: string = "",
        public SpContactPersonLastName: string = "",
        public SpContactEmail: string = "",
        public SpContactPhone: string = "",
        public SpFinanceEmail: string = "",
        public SpAddress1: string = "",
        public SpAddress2: string = "",
        public SpAddressCity: string = "",
        public SpAddressPin: string = "",
        public CreatedBy: string = "",
        public LastUpdatedBy: string = "",

    ) {

    }
}


export class searchForm {
    constructor(
        public searchField: string = ""
    ) {
    }
}

export class forgotPasswordDetails {
    constructor(
        public phoneNumber: string = "",
        public otpVar: string = "",
        public newPassword: string = "",
        public confirmPassword: string = ""
    ) {
    }
}



export class tabDetails {
    constructor(
        public icon: string = "",
        public label: string = "",
        public title: string = "",
        public flag: string = ""
    ) {
    }
}

export class ticketDetails {
    constructor(
        public TicketSiteId: string = "",
        public TicketAssetId: number = 0,
        public TicketNotificationTo = [],
        public TicketCategoryId: string = "",
        public TicketTypeId: string = "",
        public TicketSeverityId: number = 0,
        public TicketPriorityId: number = 0,
        public TicketCreatedBy: string = JSON.parse(atob(localStorage.getItem('spUserData')))['UserUUID'],
        public TicketRequestedBy: string = "",
        public TicketAssignedTo: number = 0,
        public TicketStatusId: number = 1,
        public TicketSubject: string = "",
        public TicketUpdateComments: string = "",
        public TicketDescription: string = "",
        public TicketAdditonalNotes: string = "",
        public TicketVisualsURL1: string = "",
        public TicketVisualsURL2: string = "",
        public TicketVisualsURL3: string = "",
        public TicketAttachments = {},
        public TargetCompletionDate: string = "",
        public TicketCreatedLocationLat: string = "1",
        public TicketCreatedLocationLong: string = "1",
        public SystemIntegrationId: number = 1,
        public UpdatedBy: number = JSON.parse(atob(localStorage.getItem('spUserData')))['UserUUID'],
        public ClosedBy: number = JSON.parse(atob(localStorage.getItem('spUserData')))['UserUUID'],
        public ApprovedBy: number = JSON.parse(atob(localStorage.getItem('spUserData')))['UserUUID'],
        public IsTicketClosed: number = 0,
        public IsTicketApproved: number = 0,
    ) { }
  }

    
  export class MMRConfigurationDetails {
    constructor(
      public siteId: string="",
      public assetId: string="",
      public assetQuestion: string ="",
      public patternOnAttribute: number=1,
      public operatorType: number=1,
      public operationValue: number=1,
      public chartType: number=3,
      public MMRname: string="",
      public MMRComment: string="",
      public status: number=1
    ){ } 
}