import { iHSL } from '@amcharts/amcharts4/core';
import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  constructor() { }

  generateExcel(title, header, alignment, data: any[], flagArray, colWidth, exportDateTime, excelFileName: string, siteId, fromDate, toDate) {
  
     //Create workbook and worksheet
     let workbook = new Workbook();    
     let worksheet = workbook.addWorksheet('Data');
     
    // if(title!==""){ 
       //Add Row and formatting
      let titleRow = worksheet.addRow([title]);
      titleRow.font = { name: 'Comic Sans MS', family: 4, size: 16, underline: 'double', bold: true }
      worksheet.addRow([]);
      // let subTitleRow = worksheet.addRow(['Date : ' + this.datePipe.transform(new Date(), 'medium')])

      //Blank Row 
      worksheet.addRow([]);
      let subTitleRow = worksheet.addRow(['Date : ' + exportDateTime])
      subTitleRow.font={
        bold: true,
      }
      //Blank Row 
      if(siteId!==""){
        worksheet.addRow([]);
        var cell = worksheet.getCell("A5");
        cell.value="SiteID: "+ siteId;
        var cell = worksheet.getCell("B5");
        cell.value="Attendance Start Date: "+ fromDate;
        var cell = worksheet.getCell("C5");
        cell.value="Attendance End Date: "+ toDate;
      }
    
      //Blank Row 
      worksheet.addRow([]);
    // }else{
      let instructionSheet = workbook.addWorksheet('Instructions');
          instructionSheet.addRow ([]);
          instructionSheet.addRow (["1. Please do not add/modify/delete the predefined columns in sheet."]);
          instructionSheet.addRow (['2. Enter only valid data in the sheet. Where there is no data, enter 0 or keep the cell blank. Do not enter "N/A", "Not Applicable" etc.']);
          instructionSheet.addRow (["3. Enter the date and time in correct format only.(Date- DD-MM-YYYY and Time - HH:MM)"]);
          instructionSheet.addRow (["4. Date should not be future date."]);
          instructionSheet.addRow (["5. Do not skip any rows blank in between."]);
          instructionSheet.eachRow(r=>{
            instructionSheet.mergeCells(`A${r.number}:M${r.number}`)
            r.getCell(1).fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FFCCFFE5' }
            };
          });
    // } 

     
    //Add Header Row
    let headerRow = worksheet.addRow(header);
   
    // header row pattern and color
    headerRow.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF00BFFF' },
        bgColor: { argb: '' },
      }
      cell.font = {
        bold: true,
      }
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
      //alignment given else default left 
      if(alignment=="right")
        cell.alignment = {horizontal: "right", wrapText: true}
      else
        cell.alignment = {horizontal: "left", wrapText: true}
    })
    if(data.length==0)
      worksheet.addRow([]);
  
    
    // worksheet.addRows(data);
    //set pattern and color to each row
    let i=0;
        
    data.forEach(d=>{
      let dataRow = worksheet.addRow(d);
      // to change the text color to red whose flag is 1
      if(flagArray.length!=0){
          for(let j=0;j<d.length;j++){
            if(alignment=="right")
              dataRow.getCell(j+1).alignment = {horizontal: "right", wrapText: true}
            else
              dataRow.getCell(j+1).alignment = {horizontal: "left", wrapText: true}
            if(flagArray[i][j]==1){
              dataRow.getCell(j+3).font = {color: {argb: "FFFF0000"}};
            }
          }
        }
    
      if(dataRow.number%2==0){
        dataRow.eachCell((cell) => {
          cell.fill={
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFF0F8FF' },
            bgColor: { argb: 'FF000000' }
          }
        });
      }
      i++;
    });
    
    //Set column width
    for(let i=1;i<=worksheet.actualColumnCount;i++){
      worksheet.getColumn(i).width=colWidth[i-1]; 
    }
    if(title!==""){ 
      worksheet.addRow([]);
      //Footer Row
      let footerRow = worksheet.addRow(['This is system generated excel sheet.']);
      footerRow.getCell(1).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFCCFFE5' }
      };
      
   
      let colNum;
      header.length>26 ? colNum=26 : colNum=header.length;
        
      let lastCol=(colNum + 9).toString(36).toUpperCase();
      footerRow.getCell(1).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
      //Merge Cells
      worksheet.mergeCells(`A${footerRow.number}:${lastCol}${footerRow.number}`);
    }
  //Generate Excel File with given name
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, excelFileName+'.xlsx');
    });
     
  }

  
  generateAttendanceExcel(title, header, alignment, data: any[], flagArray, colWidth, exportDateTime, excelFileName: string, siteId, startDate, endDate) {
  
    //Create workbook and worksheet
    let workbook = new Workbook();  
    let instructionSheet = workbook.addWorksheet('Instructions');
    
          instructionSheet.addRow([]);
          instructionSheet.addRow(["1. Please do not add/modify/delete the predefined columns in sheet."]);
          instructionSheet.addRow(["2. Please do not modify/delete existing attendance data in sheet."]);
          instructionSheet.addRow(['3. Enter only valid data in the sheet. Where there is no data, keep the cell blank. Do not enter "N/A", "Not Applicable" etc.']);
          instructionSheet.addRow(['4.  Enter the date and time in correct format only. (Date- DD-MM-YYYY and Time - HH:MM)']);
          instructionSheet.addRow(["5. Date should not be future date."]);
          instructionSheet.addRow(["6. Do not skip any rows blank in between."]);
          instructionSheet.eachRow(r=>{
          instructionSheet.mergeCells(`A${r.number}:M${r.number}`)
            r.getCell(1).fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FFCCFFE5' },
            };
          });
        
          const zCol = instructionSheet.getColumn('Z');
          zCol.values = ["12:00 AM","12:30 AM","1:00 AM","1:30 AM","2:00 AM","2:30 AM","3:00 AM","3:30 AM","4:00 AM","4:30 AM","5:00 AM","5:30 AM","6:00 AM","6:30 AM","7:00 AM","7:30 AM","8:00 AM","8:30 AM","9:00 AM","9:30 AM","10:00 AM","10:30 AM","11:00 AM","11:30 AM","12:00 PM","12:30 PM","1:00 PM","1:30 PM","2:00 PM","2:30 PM","3:00 PM","3:30 PM","4:00 PM","4:30 PM","5:00 PM","5:30 PM","6:00 PM","6:30 PM","7:00 PM","7:30 PM","8:00 PM","8:30 PM","9:00 PM","9:30 PM","10:00 PM","10:30 PM","11:00 PM","11:30 PM"];
          zCol.eachCell((cell) => {
            cell.font={
              color: {
                argb: 'FFFFFFFF'
              }
            }                  
          });
          
          
   let worksheet = workbook.addWorksheet('Attendance');
    
   if(title!==""){ 
      //Add Row and formatting
     let titleRow = worksheet.addRow([title]);
     titleRow.font = { name: 'Comic Sans MS', family: 4, size: 16, underline: 'double', bold: true }
     worksheet.addRow([]);
     // let subTitleRow = worksheet.addRow(['Date : ' + this.datePipe.transform(new Date(), 'medium')])
    
     //Blank Row 
     worksheet.addRow([]);
     let subTitleRow = worksheet.addRow(['Date : ' + exportDateTime])
     subTitleRow.font={
       bold: true,
     }

     
     var cell = worksheet.getCell("B4");
     cell.value="SiteID: "+ siteId;
     var cell = worksheet.getCell("C4");
     cell.value="Attendance Start Date: "+ startDate;
     var cell = worksheet.getCell("D4");
     cell.value="Attendance End Date: "+ endDate;
     
     //Blank Row 
     worksheet.addRow([]);
   } 

    
   //Add Header Row
   let headerRow = worksheet.addRow(header);
  
   // header row pattern and color
   headerRow.eachCell((cell) => {
     cell.fill = {
       type: 'pattern',
       pattern: 'solid',
       fgColor: { argb: 'FF00BFFF' },
       bgColor: { argb: '' },
     }
     cell.font = {
       bold: true,
     }
     cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
     //alignment given else default left 
     if(alignment=="right")
       cell.alignment = {horizontal: "right", wrapText: true}
     else
       cell.alignment = {horizontal: "left", wrapText: true}
   })
   if(data.length==0)
     worksheet.addRow([]);
 
   
   // worksheet.addRows(data);
   //set pattern and color to each row
   let i=0;
   
   data.forEach(d=>{
     let dataRow = worksheet.addRow(d);
       for(let l=5;l<=header.length;l++){        
         dataRow.getCell(l).dataValidation = {
           type: "list",
           formulae: ['Instructions!$Z$1:$Z$48']
         };
         if(dataRow.getCell(l).value!=""){
          // dataRow.getCell(l).font = {color: {argb: "004e47cc"}};
          dataRow.getCell(l).value = {
            richText: [
              {
                text: '*',
                font: {
                  color: {
                    argb: 'FFFFFFFF'
                  },
                },
              },
              {
                text: `${dataRow.getCell(l).value}`,
                font: {
                  color: {
                    argb: '004e47cc'
                  },
                },
              },
            ],
          };
         }
        }
     
     // to change the text color to red whose flag is 1
     if(flagArray.length!=0){
         for(let j=0;j<d.length;j++){
           if(alignment=="right")
             dataRow.getCell(j+1).alignment = {horizontal: "right", wrapText: true}
           else
             dataRow.getCell(j+1).alignment = {horizontal: "left", wrapText: true}
           if(flagArray[i][j]==1){
             dataRow.getCell(j+3).font = {color: {argb: "FFFF0000"}};
           }
         }
       }
   
     if(dataRow.number%2==0){
       dataRow.eachCell((cell) => {
         cell.fill={
           type: 'pattern',
           pattern: 'solid',
           fgColor: { argb: 'FFF0F8FF' },
           bgColor: { argb: 'FF000000' }
         }
       });
     }
     i++;
   });
   
   //Set column width
   for(let i=1;i<=worksheet.actualColumnCount;i++){
     worksheet.getColumn(i).width=colWidth[i-1]; 
   }
   if(title!==""){ 
     worksheet.addRow([]);
     //Footer Row
     let footerRow = worksheet.addRow(['This is system generated excel sheet.']);
     footerRow.getCell(1).fill = {
       type: 'pattern',
       pattern: 'solid',
       fgColor: { argb: 'FFCCFFE5' }
     };
     
     let colNum;
     header.length>26 ? colNum=26 : colNum=header.length;
       
     let lastCol=(colNum + 9).toString(36).toUpperCase();
     footerRow.getCell(1).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
     //Merge Cells
     worksheet.mergeCells(`A${footerRow.number}:${lastCol}${footerRow.number}`);
   }
 //Generate Excel File with given name
   workbook.xlsx.writeBuffer().then((data) => {
     let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
     fs.saveAs(blob, excelFileName+'.xlsx');
   });    
 }
}