import { Pipe, PipeTransform, ChangeDetectorRef } from "@angular/core";

@Pipe({
    name: "baseImage"
})

export class ImagePipe implements PipeTransform {
    constructor(private cd: ChangeDetectorRef) { }
    transform(terminalImage: any): any {

        let tempImgData = terminalImage;
        let data = new Uint8Array(tempImgData);
        let base64 = this.bufferToBase64(data);
        let base64Img = "data:image/jpeg;base64," + base64;
        this.cd.detectChanges();
        return base64Img;
    }

    bufferToBase64(buf) {
        var binstr = Array.prototype.map.call(buf, function (ch) {
            return String.fromCharCode(ch);
        }).join('');
        return btoa(binstr);
    }
}