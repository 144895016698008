import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from '../../../environments/environment';
@Injectable({ providedIn: 'root' })

export class OnTdmPpmService {
  constructor(public httpClient: HttpClient) {}
    getPpmExecutionDetails(params){
      return this.httpClient.post(environment.authApiBase + '/tdmPpm/getPpmExecutionDetails', params, { withCredentials: false });
    }
}