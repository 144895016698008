import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })


export class CMService {
    body: any;

    constructor(public httpClient: HttpClient) { }

    //to get invOutStandingDetails from server
    toGetOutStandingDetails(flag) {
        this.body = {
            spUuid: JSON.parse(atob(localStorage.getItem('spUserData')))['SpUUID'],
            invoiceDays: flag
        }
        return this.httpClient.post(environment.authApiBase + '/credit/getOutStandingDetails', this.body);
    }


    //to get listing of all invoices for specific SPid
    getAllInvoicesListing(data) {
        return this.httpClient.post(environment.authApiBase + '/credit/getAllInvoicesListing', data, { withCredentials: false });
    }



    //update invoice amount as per status paid/partial
    updateInvoiceAmount(data) {
        return this.httpClient.post(environment.authApiBase + '/credit/updateInvoice', data, { withCredentials: false });

    }




    //listing of onboarding client listing
    getOnBoardingListing() {

        this.body = {
            spUuid: JSON.parse(atob(localStorage.getItem('spUserData')))['SpUUID']
        }
        return this.httpClient.post(environment.authApiBase + '/credit/getOnBoardingListing', this.body);
    }

    //to update cm reminders
    saveReminderDetails(data) {

        return this.httpClient.post(environment.authApiBase + '/credit/updateReminderDetails', data, { withCredentials: false });
    }



    /*************************** */
    //to get clientReminder details for specific instance ID
    getReminderDetails(reminderInstanceId) {
        return this.httpClient.post(environment.authApiBase + '/credit/getReminderDetails', { reminderInstanceId: reminderInstanceId }, { withCredentials: false });
    }

    getBankList() {
        return this.httpClient.get(environment.authApiBase + '/credit/getBankList');
    }

    getPaymentModeList() {
        return this.httpClient.get(environment.authApiBase + '/credit/getPaymentModeList');
    }

    getInvoiceDetails(invoiceNo) {
        return this.httpClient.post(environment.authApiBase + '/credit/getInvoiceDetails', { invoiceNumber: invoiceNo }, { withCredentials: false });
    }

}