import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })


export class UserAttendanceService {

    constructor(public httpClient: HttpClient) { }

    getUserAttendanceRecord(data) {
        let param = data;
        return this.httpClient.post(environment.authApiBase + '/userAttendance/getUserAttendanceHours', param, { withCredentials: false });
    }

    getUserAttendanceInstance(data){
        let param = data;
        return this.httpClient.post(environment.authApiBase + '/userAttendance/getUserAttendanceInstanceDetails', param, { withCredentials: false }); 
    }

    getAttendanceDetailsForExport(data){
      let param = data;
      return this.httpClient.post(environment.authApiBase + '/userAttendance/getAttendanceDetailsForExport', param, { withCredentials: false }); 
    }

    submitUserAttendance(data){
      let param = data;
      return this.httpClient.post(environment.authApiBase + '/userAttendance/submitManualUserAttendance', param, { withCredentials: false }); 
    }

    updateUserAttendance(data){
      let param = data;
      return this.httpClient.post(environment.authApiBase + '/userAttendance/updateUserAttendance', param, { withCredentials: false });  
    }

    getAttendedDaysCount(param){
      return this.httpClient.post(environment.authApiBase + '/userAttendance/getAttendedDaysCount', param, { withCredentials: false });  
    }

}