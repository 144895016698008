import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { HttpClient } from "@angular/common/http";
import { environment } from '../../../environments/environment';


@Injectable()
export class AuthUserService {
  token: string;

  constructor(
    private router: Router,
    private titleService: Title, private httpClient: HttpClient
  ) { }


  //to get listing of assets for URL rbac security
  rbacSecurity(role_id, current_url) {
    const data = { role_id, current_url }
    return this.httpClient.post(environment.authApiBase + '/master/getUrls', data);
  }



  //to set header title as per current page
  /*setHeader(current_url) {
    if (current_url == "/Home") {
      this.titleService.setTitle("SIMBA");
    }
    if (current_url == "/MerchantApplication") {
      this.titleService.setTitle("SIMBA ");
    }
    if (current_url == "/MyApplication") {
      this.titleService.setTitle("SIMBA");
    }
    if (current_url == "/BusinessDetails") {
      this.titleService.setTitle("SIMBA");
    }

    if (current_url == "/login") {
      this.titleService.setTitle("SIMBA");
    }

    if (current_url == "/Reset") {
      this.titleService.setTitle("SIMBA");
    }
    if (current_url == "/Verification") {
      this.titleService.setTitle("SIMBA");
    }
    if (current_url == "/SetPassword") {
      this.titleService.setTitle("SIMBA");
    }
    if (current_url == "/MerchantApplications") {
      this.titleService.setTitle("SIMBA");
    }
    if (current_url == "/FeeTemplates") {
      this.titleService.setTitle("SIMBA");
    }
  }*/




}
