import { Component, OnInit, Output, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import { DatatableComponent } from "@swimlane/ngx-datatable/release";

export class companyDB {
    static data = [
        {
            "name": "Ethel Price",
            "gender": "female",
            "company": "Johnson, Johnson and Partners, LLC CMP DDC",
            "age": 22
        },
        {
            "name": "Claudine Neal",
            "gender": "female",
            "company": "Sealoud",
            "age": 55
        },
        {
            "name": "Beryl Rice",
            "gender": "female",
            "company": "Velity",
            "age": 67
        },
        {
            "name": "Wilder Gonzales",
            "gender": "male",
            "company": "Geekko"
        },
        {
            "name": "Georgina Schultz",
            "gender": "female",
            "company": "Suretech"
        },
        {
            "name": "Carroll Buchanan",
            "gender": "male",
            "company": "Ecosys"
        },
        {
            "name": "Valarie Atkinson",
            "gender": "female",
            "company": "Hopeli"
        },
        {
            "name": "Schroeder Mathews",
            "gender": "male",
            "company": "Polarium"
        },
        {
            "name": "Lynda Mendoza",
            "gender": "female",
            "company": "Dogspa"
        },
        {
            "name": "Sarah Massey",
            "gender": "female",
            "company": "Bisba"
        },
        {
            "name": "Robles Boyle",
            "gender": "male",
            "company": "Comtract"
        },
        {
            "name": "Evans Hickman",
            "gender": "male",
            "company": "Parleynet"
        },
        {
            "name": "Dawson Barber",
            "gender": "male",
            "company": "Dymi"
        },
        {
            "name": "Bruce Strong",
            "gender": "male",
            "company": "Xyqag"
        },
        {
            "name": "Nellie Whitfield",
            "gender": "female",
            "company": "Exospace"
        },
        {
            "name": "Jackson Macias",
            "gender": "male",
            "company": "Aquamate"
        },
        {
            "name": "Pena Pena",
            "gender": "male",
            "company": "Quarx"
        },
        {
            "name": "Lelia Gates",
            "gender": "female",
            "company": "Proxsoft"
        },
        {
            "name": "Letitia Vasquez",
            "gender": "female",
            "company": "Slumberia"
        },
        {
            "name": "Trevino Moreno",
            "gender": "male",
            "company": "Conjurica"
        },
        {
            "name": "Barr Page",
            "gender": "male",
            "company": "Apex"
        },
        {
            "name": "Kirkland Merrill",
            "gender": "male",
            "company": "Utara"
        },
        {
            "name": "Blanche Conley",
            "gender": "female",
            "company": "Imkan"
        }
    ]
}



@Component({
    selector: 'app-ngx-listing',
    templateUrl: './ngx-listing.component.html',
    styleUrls: ['./ngx-listing.component.scss']

})

export class NgxListingComponent {

    public filteredData = [];

    public columns = [
        { prop: 'name', name: 'Name' },
        { prop: 'company', name: 'COM' },
        { prop: 'gender', name: 'GEN' }
    ];
    @ViewChild('DatatableComponent', { static: true }) table: DatatableComponent;
    responseData: any;
    listingData: any;

    constructor() {
        this.responseData = companyDB.data;
        this.listingData = companyDB.data;
    }



    //searched string in search-box
    updateFilter(event) {

        const val = event.toLowerCase().trim();

        if (!val) {
            // .siteListingAPIcallingRBAC()
            this.listingData = this.responseData;
            return
        }
        // filter our data
        const filteredData = this.responseData.filter(function (d) {
            return (d.name.toLowerCase().indexOf(val) !== -1 ||
                d.company.toLowerCase().indexOf(val) !== -1 ||
                d.gender.toLowerCase().indexOf(val) !== -1 || !val);
        });

        // update the rows
        this.listingData = filteredData;

    }

}
