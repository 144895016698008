import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { filter } from 'rxjs/operators';
import { Router, NavigationExtras, NavigationEnd } from "@angular/router";
import { Subject } from 'rxjs';


@Component({
  selector: 'app-listing-loader',
  templateUrl: './listing-loader.component.html',
  styleUrls: ['./listing-loader.component.scss']
})



export class ListingLoaderComponent implements OnInit {

  @Input() spinnerFlag;  //for singlecomp multile times

  @Input() changing: Subject<string>

  hideSpinner: boolean = true;

  //for singlecomp multile times
  centreSpinner: boolean = false

  constructor(private router: Router) {
    localStorage.setItem('listingSpinner', "true");
    this.router.events
      .pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd))
      .subscribe(event => {
        if (
          event.id === 1 &&
          event.url === event.urlAfterRedirects
        ) {
          this.hideSpinner = false
        }
      })
  }

  ngOnInit() {
    //to check flag is true or false when data is came from server localstoarage will be false,if false then single page navigation spinner on listing will be hide
    setInterval(() => {
      if (localStorage.getItem('listingSpinner') == "false") {
        this.hideSpinner = false
        // localStorage.setItem('listingSpinner', "true");
        return false;
      }
    }, 1000);

    //this loader is for who are multiple times called from one component
    this.otherLoaders()


  }

  //this loader is for who are multiple times called from one component
  otherLoaders() {

    if (this.spinnerFlag == "true") {
      this.hideSpinner = false
      this.centreSpinner = true
    }
    var clickLoader = setInterval(() => {
      if (this.spinnerFlag == "true") {
        this.centreSpinner = false
        return false;
      }
    }, 1000);

    if (this.spinnerFlag == "true") clearInterval(clickLoader)


  }

  ngOnDestroy() { }

}
