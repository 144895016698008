import { Injectable } from '@angular/core';
//import { Socket } from 'ngx-socket-io';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class WebSocketService {


    /*constructor(private socket: Socket) { }


    public sendMessage(message) {
        this.socket.emit('new-message', message);
    }


    public getMessages = () => {
        return Observable.create((observer) => {
            this.socket.on('get-message', (message) => {
                observer.next(message);
            });
        });
    }
    //socket trial demo
    public getFixedAssets = () => {
        return Observable.create((observer) => {
            this.socket.on('get-fixed-assets', (message) => {
                observer.next(message);
            });
        });
    }*/

}
