import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from "../shared/services/index";
import { SweetAlertService } from "../utilService/index";
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { ToastrService } from "ngx-toastr";

@Injectable()

export class ErrorInterceptor implements HttpInterceptor {

  public timeoutExpired: Subject<number> = new Subject<number>();

  constructor(private toastrService: ToastrService, private authenticationService: AuthenticationService, private router: Router, private sweetAlertService: SweetAlertService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {

      //0 is when server is down
      if (err.status === 0) {
        //Hide Spinner
        localStorage.setItem('listingSpinner', "false");
        this.toastrService.warning('Server is down, please try again!');
      }
      //500 if any error occurs in BE by developer
      if (err.status === 500) {
        //Hide Spinner
        localStorage.setItem('listingSpinner', "false");
        this.toastrService.warning('Internal issue, please contact administrator.');
      }
      if (err.status === 401) { //401 from API
        localStorage.setItem('listingSpinner', "false");
      }

      /*
      //temp commented today - 31 jan 2020
            // Check Server Status
             if(err.status === 0){
                var routerUrl = environment.frontApiBase+'/login';
                var redirectUrl = window.location.href;

                //Check redirect url with baseurl
                 if(routerUrl !== redirectUrl){
                  this.sweetAlertService.sweetAlert(1, "error", "Server Not Found", 'Error 500', "serverError")
                    setTimeout(() => {
                      this.authenticationService.logout();
                    }, 3000);
                 }
            }
            */

      const error = err.error.message || err.statusText;
      return throwError(error);
    }))
  }

}

/**
 * in update-titkcet-management.component.ts in ngOnInit()
 *
 * //to check authorization for SP
    // this.secureService.authorizationService([this.siteId.substring(0, 3), this.clientId.substring(0, 3), this.propertyId.substring(0, 3)], "ticketDetails")

    // console.log(this.toVerifyByClientID, this.siteId, this.propertyId, this.ticketId)
/* if ((this.propertyId !== 'undefined' || this.siteId !== 'undefined') && (
   this.siteId.substring(0, 3) != JSON.parse(atob(localStorage.getItem('spUserData')))['SpUUID'].toString() ||
   this.propertyId.substring(0, 3) != JSON.parse(atob(localStorage.getItem('spUserData')))['SpUUID'].toString() ||
   this.clientId.substring(0, 3) != JSON.parse(atob(localStorage.getItem('spUserData')))['SpUUID'].toString()

 )) {
   this.sweetAlertService.authorizationService('', "warning", "Warning", "You are not authorized!", "authorization")
   return false;
 }*/