import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { UIUtilService, dateTimeUtilService } from "../../../utilService/index";
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { NgbDateAdapter, NgbDateStruct, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Component({
  selector: 'app-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.scss'],
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }]
})
export class DateFilterComponent implements OnInit {
  makeActive: number = 0;
  monthList: any = [];
  dateFilterForm: FormGroup;
  selectedFilter: any;
  minDate: any;
  maxDate: any;
  @Output() dateFilterEvent = new EventEmitter<string>();
  dateRange: any;
  setMaxDate : { year: number; month: number; day: number; };
  
  constructor( private DateTimeUtilService: dateTimeUtilService, private UIUtilService: UIUtilService,) {
    const current = new Date();
    let currentDate = (current.getMonth() + 1)+"/"+current.getDate()+"/"+current.getFullYear();
    this.setMaxDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate()
    };
   
    const last30DaysDate=new Date(new Date().setDate(new Date().getDate() - 30));
    let priorDate = (last30DaysDate.getMonth() + 1)+"/"+last30DaysDate.getDate()+"/"+last30DaysDate.getFullYear();
  
    this.dateFilterForm =new FormGroup({
      selectedMonth : new FormControl(0),
      fromDate: new FormControl(new Date(priorDate)),
      toDate: new FormControl(new Date(currentDate))
    });
   }

  ngOnInit() {
    let ticketDateRange:any;
    ticketDateRange = JSON.parse(localStorage.getItem('ticketDateRange'));  
    this.monthList = this.DateTimeUtilService.getMonthsArray(12);
  
    // if dates receive from dashboard
    if(ticketDateRange){
      this.setFilterDates(ticketDateRange);
    }
    else{// regular date filter function
      this.selectedFilter=0;
      this.getDates(30);
    }
  }

 

  monthSelection(selectedValue, flag){
    this.makeActive = flag;
    this.selectedFilter = selectedValue;
    if(selectedValue==0) 
      this.getDates(30);
    else if (selectedValue==1)
      this.getDatepickerDates();
    else if(selectedValue!==undefined){
      let selMonth=selectedValue.split('-')[1];
      let selYear=selectedValue.split('-')[0];
      this.minDate = this.UIUtilService.getInYMD(moment([selYear, selMonth - 1]).toDate());
       // Clone the value before .endOf()
      this.maxDate = this.UIUtilService.getInYMD(moment(this.minDate).endOf('month').toDate());
      this.dateRange={minDate:this.minDate, maxDate: this.maxDate, dateFilterSelectedValue: this.selectedFilter};
      this.dateFilterEvent.emit(this.dateRange);
    }
  }


  getDates(NumOfDays){
    let prevdate;

  
    switch(NumOfDays){
      case 30: prevdate=moment().subtract(29, 'days').toDate(); this.makeActive = 30; break;
      case 60: prevdate=moment().subtract(59, 'days').toDate(); this.makeActive = 60; break;
      case 90: prevdate=moment().subtract(89, 'days').toDate(); this.makeActive = 90; break;
      case 120: prevdate=moment().subtract(119, 'days').toDate(); this.makeActive = 120; break;
    }
  
    this.maxDate= this.UIUtilService.getInYMD(new Date());
    this.minDate= this.UIUtilService.getInYMD(new Date(prevdate));
    // console.log(this.minDate," ",this.maxDate);
    this.dateRange={minDate:this.minDate, maxDate: this.maxDate, dateFilterSelectedValue:this.selectedFilter};
    this.dateFilterEvent.emit(this.dateRange);
  }

  //set date values on ticket listing page date filter which selected on dashboard
  setFilterDates(dashboardDates){
    this.selectedFilter = dashboardDates.selectedOption;
    let diffInTime=(new Date(dashboardDates.toDate).getTime())-(new Date(dashboardDates.fromDate).getTime());
    let dateDiff=diffInTime/(1000 * 3600 * 24)+1; 
    (this.dateFilterForm.controls["selectedMonth"]as any).setValue(this.selectedFilter);
    if(dashboardDates.selectedOption==0){
      switch (dateDiff){
        case 29: case 30: this.makeActive = 30; break;
        case 59: case 60: this.makeActive = 60; break;
        case 89: case 90: this.makeActive = 90; break;
        case 119: case 120: this.makeActive = 120; break;
      }
    }else if(dashboardDates.selectedOption==1){
      (this.dateFilterForm.controls["fromDate"]as any).value=new Date(dashboardDates.fromDate);
      (this.dateFilterForm.controls["toDate"]as any).value=new Date(dashboardDates.toDate);
    }
  }

  getDatepickerDates(){
    if(this.dateFilterForm.controls['fromDate'].value!="" && this.dateFilterForm.controls['toDate'].value!= ""){
      this.minDate= this.UIUtilService.getInYMD(this.dateFilterForm.controls["fromDate"].value);
      this.maxDate= this.UIUtilService.getInYMD(this.dateFilterForm.controls["toDate"].value);
      // console.log(this.minDate," ",this.maxDate);
      this.dateRange={minDate:this.minDate, maxDate: this.maxDate, dateFilterSelectedValue:this.selectedFilter};
      this.dateFilterEvent.emit(this.dateRange);
    }
  }
  
}
