import { Component, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-confirm-dialog',
  template: `
    <div class="modal-header">
      <h4 class="modal-title">{{confirmationBoxTitle}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.close(false)">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div id="confirmationMessage"></div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-primary" type="button" (click)="activeModal.close(true)">Yes!!</button>
      <button type="button" class="btn btn-secondary" (click)="activeModal.close(false)">Close</button>
    </div>
  `,
  styles: []
})

export class ConfirmDialog {
  @Input() public confirmationBoxTitle;
  @Input() public confirmationMessage;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    let messageContainer = <HTMLElement>document.querySelector("#confirmationMessage");
    messageContainer.innerHTML = this.confirmationMessage;
  }
  
}