import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from "@angular/router";
import { Injectable } from "@angular/core";
import { AuthUserService } from "./auth.service";


@Injectable()
//rbac for url accessing to roles
export class RoleGuard implements CanActivate {
  constructor(
    private router: Router, private AuthUserService: AuthUserService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    let current_url = state.url;

    if (localStorage.getItem('token')) {

      //flag used for rbac handling
      if (sessionStorage.getItem('viewedFrom') == 'fromLogin') {
        sessionStorage.removeItem("viewedFrom");
        return true;
      }

      this.AuthUserService.rbacSecurity(JSON.parse(atob(localStorage.getItem('spUserData')))['uRoleId'], current_url).subscribe(data => {

        if (data['result'][0]) {
          //console.log('exists')
        } else {
          this.router.navigate(["/notFound"]);
          // console.log('doesnt exist')
        }

      })

      return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login']);
    //    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });

    return false;
  }
}
