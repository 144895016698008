import { RouteEventsService } from 'src/app/utilService';
import { searchForm } from 'src/app/utilService/class-declarations';
import { FormBuilder, FormGroup } from "@angular/forms";
import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';


@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss'],
  providers: [
    RouteEventsService
  ]
})
export class SearchBoxComponent implements OnInit, OnDestroy {

  //to get value
  @Output() searchedValue = new EventEmitter<string>();


  constructor(private formBuilder: FormBuilder) { }
  searchForm: FormGroup;
  //instance
  searchFormInstance = new searchForm();

  ngOnInit() {
    //set form group
    this.searchForm = this.formBuilder.group(this.searchFormInstance);
  }

  //searched values from listing
  updateFilter(event) {
    const val = event.target.value.toLowerCase().trim();
    this.searchedValue.emit(val)
    //if searchbox is empty then all data
    setTimeout(() => {
      if (!this.searchForm.controls.searchField.value) {
        this.searchedValue.emit('');
      }
    }, 1000);
  }

  ngOnDestroy() {

  }



}
