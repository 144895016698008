import { Routes } from "@angular/router";
// import { AssetModule } from "src/app/assetManagement/asset.module";
// import { InvoiceEntryModule } from "src/app/creditManagement/invoiceEntry.module";

export const content: Routes = [

  {
    path: "",
    loadChildren: "./auth/prelogin.module#PreLoginModule",
    data: {
      breadcrumb: ""
    }
  },
  {
    path: "creditManagment",
    loadChildren: "./creditManagement/cm.module#CMModule",
    data: {
      breadcrumb: ""
    }
  },
  {
    path: "dashboard",
    loadChildren: "./home/home.module#homeModule",
    data: {
      breadcrumb: ""
    }
  },
  {
    path: "simba-iot",
    loadChildren: "./simbaIoT/simba-iot.module#IoTModule",
    data: {
      breadcrumb: ""
    }
  },
  {
    path: "on-boarding",
    loadChildren: "./onboarding/onboarding.module#OnboardingModule",
    data: {
      breadcrumb: ""
    }
  },
  {
    path: "ticket",
    loadChildren: "./ticket/ticket.module#TicketModule",
    data: {
      breadcrumb: ""
    }
  },
  {
    path: "work-order",
    loadChildren: "./workOrder/work-order.module#WorkOrderModule",
    data: {
      breadcrumb: ""
    }
  },
  {
    path: "soft-services",
    loadChildren: "./softServices/soft-services.module#SoftServicesModule",

    data: {
      breadcrumb: ""
    }
  },
  {
    path: "asset-management",
    loadChildren: "./assetManagement/asset.module#AssetModule",
    data: {
      breadcrumb: ""
    }
  },
  {
    path: "TDM",
    loadChildren: "./tdmDashboard/tdm.module#TDMModule",
    data: {
      breadcrumb: ""
    }
  },
  {
    path: "import",
    loadChildren: "./importData/import.module#ImportModule",
    data: {
      breadcrumb: ""
    }
  },
  {
    path: "simba-admin",
    loadChildren: "./simbaAdmin/simba-admin.module#SimbaAdminModule",
    data: {
      breadcrumb: ""
    }
  },
  {
    path: "training-material",
    loadChildren: "./trainingMaterial/training.module#TrainingModule",
    data: {
      breadcrumb: ""
    }
  },
  {
    path: "scheduler",
    loadChildren: "./scheduler/schedule-details.module#ScheduleDetailsModule",
    data: {
      breadcrumb: ""
    }
  },
  {
    path: "sp-simba-admin",
    loadChildren: "./spSimbaAdmin/sp-simba-admin.module#SpSimbaAdminModule",
    data: {
      breadcrumb: ""
    }
  },

  {
    path: "attendance",
    loadChildren: "./attendance/attendance.module#AttendanceModule",
    data: {
      breadcrumb: ""
    }
  },
 
  // {
  //   path: 'dashboard',
  //   loadChildren: './components/dashboard/dashboard.module#DashboardModule',
  //   data: {
  //     breadcrumb: "Dashboard"
  //   }
  // },
  {
    path: "error",
    loadChildren: "./shared/error-pages/error-pages.module#ErrorPagesModule"
  },

  {
    path: "dynamic-attributes",
    loadChildren: "./dynamic-attributes/dynamic-attributes.module#DynamicAttributesModule",
    data: {
      breadcrumb: ""
    }
  },
];
