import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class SpMasterTableService {

  body: any;
  constructor(public httpClient: HttpClient) { }




    getSpMasterRecordsList(tableData: any) {
      return this.httpClient.post(environment.authApiBase + '/spMaster/allSpMasterRecordsList', { tableData }, { withCredentials: false });
    }

    addNewSpMasterRecords(data: any) {
      return this.httpClient.post(environment.authApiBase + '/spMaster/addSpMaster', data, { withCredentials: false });
    }

    updateSpMasterRecords(data: any) {
      return this.httpClient.post(environment.authApiBase + '/spMaster/updateSpMaster', data, { withCredentials: false });
    }

    


}
