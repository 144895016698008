
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })


export class OnTrainingService {

    constructor(public httpClient: HttpClient) { }


    getTrainingMaterialDetails(data) {
        let param = data;
        return this.httpClient.post(environment.authApiBase + '/trainingMaterial/getTrainingMaterialDetails', param, { withCredentials: false });
    }


    getTrainingVideo(data) {
        let param = data;
        return this.httpClient.post(environment.authApiBase + '/trainingMaterial/getTrainingMaterial', param, { withCredentials: false });
    }

    getTrainingCount(data) {
         let param = data;
        return this.httpClient.post(environment.authApiBase + '/trainingMaterial/countMaterialType', param, { withCredentials: false });
    }

    addTrainingData(data) {
        let param = data;
        console.log(param);
        return this.httpClient.post(environment.authApiBase + '/trainingMaterial/submitTrainingMaterial', param, { withCredentials: false });
    }

    updateTrainingData(data) {
        let param = data;
        return this.httpClient.post(environment.authApiBase + '/trainingMaterial/updateTrainingMaterialDetails', param, { withCredentials: false });
    }

    deleteTrainingData(data) {
        let param = data
        return this.httpClient.post(environment.authApiBase + '/trainingMaterial/deleteTrainingMaterialDetails', param, { withCredentials: false });
    }

    getModuleListData(data){
        let param = data
        return this.httpClient.post(environment.authApiBase + '/trainingMaterial/getModules', param, { withCredentials: false });

    }

}