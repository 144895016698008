import { Component, OnInit,ViewEncapsulation ,Output,EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { NgbDateAdapter, NgbDateStruct, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';



@Component({
  selector: 'app-schedular',
  templateUrl: './schedular.component.html',
  styleUrls: ['./schedular.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }]
})
export class SchedulerComponent implements OnInit {
  schedulerForm: FormGroup;
  default:any;
  toDate:any;
  fromDate:any;
  frequency:any;
  pattern:any;
  dayOfWeek:any;
  dayOfMonth:any;
  monthOfYear:any;
  currentYear:any;
  days:any=[];

  @Output() schedulerEvent = new EventEmitter<string>();
  @Input() viewFlag;
  constructor(private formBuilder: FormBuilder) {
    this.schedulerForm = new FormGroup({
      toDate: new FormControl(''),
      fromDate: new FormControl(''),
      frequency: new FormControl(''),
      pattern: new FormControl(''),
      dayOfMonth:new FormControl(''),
      monthOfYear:new FormControl(''),
      dayOfWeek:new FormControl('')
    })
  }

  ngOnInit() {
    this.schedulerForm.controls["frequency"].setValue(this.viewFlag);
    this.frequency=this.viewFlag;
    this.schedulerForm.controls["dayOfWeek"].setValue("MONDAY");
    this.currentYear = new Date().getFullYear();
    this.schedulerForm.controls["monthOfYear"].setValue(new Date().getMonth()+1<10?`0${new Date().getMonth()+1}`:new Date().getMonth()+1);
    this.adjustDays(`${new Date().getMonth()+1}`);
    this.schedulerForm.controls["dayOfMonth"].setValue(new Date().getDate());
   }

  setFrequencyType(val){
    this.frequency=val;
  }

  onOk(){
    let todaysDate = new Date();
    let startDate = this.schedulerForm.controls["fromDate"].value!=""?new Date(this.schedulerForm.controls["fromDate"].value).toISOString().slice(0, 10):todaysDate.toISOString().slice(0, 10);
    let endDate = new Date(this.schedulerForm.controls["toDate"].value!=""?this.schedulerForm.controls["toDate"].value:new Date(todaysDate.getFullYear()+1, todaysDate.getMonth()+1, 1)).toISOString().slice(0, 10);
  
    switch (this.frequency) {
      case 'D':
        this.pattern = "";
        break;
      case 'W':
        //this.pattern = this.schedulerForm.controls["dayOfWeek"].value;
        this.pattern = `${this.schedulerForm.controls["dayOfMonth"].value}`
        break;
      case 'M':
        this.pattern = `${this.schedulerForm.controls["dayOfMonth"].value}`;
        break;
      case 'Y':
        this.pattern = `${this.schedulerForm.controls["monthOfYear"].value}-${this.schedulerForm.controls["dayOfMonth"].value}`
        break;
    }
    let data = {
      "startDate":startDate,
      "endDate":endDate,
      "frequency":this.frequency,
      "pattern":this.pattern
    }
    this.schedulerEvent.emit(JSON.stringify(data));
  }

  adjustDays(event){
    this.days=[];
    let daysInMonth = new Date(this.currentYear, parseInt(event), 0).getDate();
    for(let i=1;i<=daysInMonth;i++){
      this.days.push(i);
    }
  }

  onCancel(){
    let data = {
      "startDate":"",
      "endDate":"",
      "frequency":"",
      "pattern":""
    }
    this.schedulerEvent.emit(JSON.stringify(data));
  }
}
