
import { ActivatedRoute, Router, NavigationExtras } from "@angular/router";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Location } from "@angular/common";

//Sweet whenever it is needed as per condition
declare var require
const sweetAlert = require("sweetalert2");

@Injectable({ providedIn: 'root' })
export class SweetAlertService {
    //Declaration
    param: any;

    flag: any = false;

    constructor(public httpClient: HttpClient, private router: Router, private location: Location) { }


    //it can be use as global/util service
    //id:to btoa() in param, flag: from which view/component it is passed that is handled in switch case
    sweetAlert(id, type, title, text, flag) {
        //SweetAlert
        sweetAlert.fire({
            type: type,
            title: title,
            text: text,
            showConfirmButton: true
        }).then(value => {

            //flag argument : from which view/component it is passed
            switch (flag) {
                case "checklistDetails":
                    var route = "/TDM/dashboard";
                    this.param = { queryParams: { data: btoa(id) } };
                    break;
                case "logsheetDetails":
                    var route = "/asset-management/asset";
                    this.param = ""
                    break;
                case "logsheetInstanceDetails":
                      var route = "/TDM/logsheetDashboard";
                      this.param = { queryParams: { data: btoa(id) } }
                      break;
                case "invoicePaid":
                case "unitBulkUpload":
                    var route = "";
                    this.param = ""
                    return false;
                case "schedule":
                    var route = "/soft-services/retailsBuilding";
                    this.param = { queryParams: { data: btoa(id) } };
                    break;
                case "scheduleAsset":
                      var route = "/asset-management/asset-view";
                      this.param = { queryParams: { data: btoa(id) } };
                      break;
                case "ticketDetails":
                    this.location.back();
                    return false;
                case "authorization":
                    this.location.back();
                    return false;
            }


            //on click of ok button it reverts back to its previuos page
            //var route and this.param is from switch case as per flag
            this.router.navigate([route], this.param);
        });
        return true;
    }

    //to secure ataz
    authorizationService(id, type, title, text, flag) {


        setTimeout(() => {
            this.location.back();
        }, 2000);

        sweetAlert.fire({
            type: type,
            title: title,
            text: text,
            showConfirmButton: true
        }).then(value => {
        });
        return true;
    }






}
