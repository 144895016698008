import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { SoftServicesService } from "src/app/shared/services/softServices.service";
import { AssetManagementService } from "src/app/shared/services//asset-management.service";

import { Location } from "@angular/common";
import { Router, NavigationExtras, ActivatedRoute } from "@angular/router";
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-qr-generation',
  templateUrl: './qr-generation.component.html',
  styleUrls: ['./qr-generation.component.scss']
})
export class QRGenerationComponent implements OnInit {
  // @Input() qrCodeSourceData;
  // @Input() viewFlag;
  qrCodes: any = [];
  qrCodeSourceData: any;
  pdfName:any;
  viewFlag: any;
  chunkArrayInGroup: any[];
  spinnerFlag = "false"
  forSpinner: boolean = false;
  constructor(private sanitizer: DomSanitizer,private router: Router, public softServicesService: SoftServicesService,private assetManagementService: AssetManagementService, public route: ActivatedRoute, private location: Location, ) {
    this.route.queryParams.subscribe(params => {
      this.qrCodeSourceData = JSON.parse(atob(params["data"]));
      this.viewFlag = atob(params["hash"]);
      
    if (this.viewFlag == "units") {
      this.pdfName=this.qrCodeSourceData[0]['BuildingName'];
    }
    else if(this.viewFlag == "asset"){
      this.pdfName=this.qrCodeSourceData[0]['SiteName'];
    }
    });

  }
  ngOnInit() {
    //to generate and get QR code
    this.generateQRCode(this.viewFlag);
  }

 

async getBase64ImageFromUrl(imageUrl) {
  var res = await fetch(imageUrl);
  var blob = await res.blob();

  return new Promise((resolve, reject) => {
    var reader  = new FileReader();
    reader.addEventListener("load", function () {
        resolve(reader.result);
    }, false);

    reader.onerror = () => {
      return reject(this);
    };
    reader.readAsDataURL(blob);
  })
}

  //as its common to all so viewFlag is flag to know from which page it is
  generateQRCode(viewFlag) {
    if (viewFlag == "units") {
      //to generate qr code of units
      this.softServicesService.generateQRCode(this.qrCodeSourceData).subscribe(data => {
        localStorage.setItem('listingSpinner', "false");
        this.qrCodes = data['result'];
        for(let i=0;i<this.qrCodes.length;i++)
        {
          this.getBase64ImageFromUrl(this.qrCodes[i].SpLogoURL)
        .then(result => this.qrCodes[i].SpLogoURL = result)
        .catch(err => console.error(err));
        }
        this.chunkArrayInGroup = this.chunkArrayInGroups(this.qrCodes, 16);//this.qrCodeSourceData[0]['unitIds'] , this.qrCodes
        if (this.qrCodes.length < 1) {
          let navigationExtras: NavigationExtras = {
            queryParams: {
              data: btoa(this.qrCodeSourceData[0]['BuildingUUID'])
            }
          };
          this.router.navigate(['/soft-services/retailsUnits'], navigationExtras);
        }
      });
    }

    if(viewFlag == "asset"){
      this.assetManagementService.generateQRCodeAssets(this.qrCodeSourceData).subscribe(data => {
        localStorage.setItem('listingSpinner', "false");
        this.qrCodes = data['result'];
        for(let i=0;i<this.qrCodes.length;i++)
        {
          this.getBase64ImageFromUrl(this.qrCodes[i].SpLogoURL)
        .then(result => this.qrCodes[i].SpLogoURL = result)
        .catch(err => console.error(err));
        }
        this.chunkArrayInGroup = this.chunkArrayInGroups(this.qrCodes, 16);
        if (this.qrCodes.length < 1) {
          let navigationExtras: NavigationExtras = {
            queryParams: {
              suuid: btoa(this.qrCodeSourceData[0]['SiteUUID'])
            }
          };
          this.router.navigate(["/on-boarding/asset-management/site-assets"], navigationExtras);
        }
      });
    }
  }


  //to download pdf
  captureScreen() {

    // console.log(this.spinnerFlag, this.forSpinner)
    this.forSpinner = true;
    this.spinnerFlag = "true";

    var dynamicDivPage = 1
    var chunkDivIdVar = ""
    let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
    var flagForPdf = '';////if yes all pdf are saved,still saving all pdf it will be no, after saving interval will be stop
    var itemsProcessed = 0; //flag to know when foreach is ended

    for (var i = 0; i < this.chunkArrayInGroup.length; i++) {
      chunkDivIdVar = "page" + dynamicDivPage;
      dynamicDivPage++;
  
      var data = document.getElementById(chunkDivIdVar);
      html2canvas(data).then(canvas => {


        // Few necessary setting options
        var imgWidth = 200; //208
        var imgHeight = canvas.height * imgWidth / canvas.width;
        const contentDataURL = canvas.toDataURL('image/png');
        var position = 0;
        pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
        // console.log(this.chunkArrayInGroup.length, this.chunkArrayInGroup.length - 1, itemsProcessed)
        if (this.chunkArrayInGroup.length - 1 === itemsProcessed) {
          console.log("finsih")
          // flagForPdf = "yes"; // to know when it is finish;
          pdf.save(this.pdfName); // Generated PDF
          this.forSpinner = false;
          this.spinnerFlag = "false";
        } else {
          pdf.addPage();
        }
        itemsProcessed++;
      });//canvasHTML ends
     
      // localStorage.setItem('listingSpinner', "false");
    }//for loop ends
    
    //if yes all pdf are saved,still saving all pdf it will be no, after saving interval will be stop
    // var interval = setInterval(function () {
    //   if (flagForPdf == "yes") {
    //     console.log("saved");
        
       
    //     pdf.save('Mypdf.pdf'); // Generated PDF
    //     clearInterval(interval);
        //logic for loader/spinner
        // this.forSpinner = false;
        // this.spinnerFlag = "false";
        // console.log(this.spinnerFlag, this.forSpinner)
    //   }
    // }, 1000);
    /*
        this.chunkArrayInGroup.forEach(function (item, index, array) {
          chunkDivIdVar = "page" + dynamicDivPage;

          var data = document.getElementById(chunkDivIdVar);
          html2canvas(data).then(canvas => {
            // Few necessary setting options
            var imgWidth = 208;
            var imgHeight = canvas.height * imgWidth / canvas.width;
            const contentDataURL = canvas.toDataURL('image/png')
            var position = 0;
            pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
            pdf.addPage()

            if (itemsProcessed === array.length) {
              flagForPdf = "yes";
            }
          });//canvasHTML ends
          dynamicDivPage++;
          console.log(chunkDivIdVar)
          itemsProcessed++;
        })//end of foreaach
    */



  }

  //to separate array in 12 section
  chunkArrayInGroups(listingArray, size) {
    var InGroupsArray = [];

    for (var i = 0; i < listingArray.length; i += size) {
      InGroupsArray.push(listingArray.slice(i, i + size));
    }
    return InGroupsArray;
  }

  //spinner for download
  spinner() {

  }

  //navigations
  navigation() {
    if (this.viewFlag == "units") {
      this.location.back();
    }
    else if (this.viewFlag == "asset") {
      this.location.back();
    }
  }

}

